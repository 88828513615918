import React, { useState } from 'react'
import images from '../helper/images';
import ApiService from '../ApiService';
import AppString from '../appstring';
import Endpoints from '../Endpoints';
import {
    useQuery,
} from 'react-query';
import CustomizeLoader from './custom_loading/custom_loader';
import { cacheTime, staleTime } from '../helper/common';

import BackgroundHeader from "../assets/img/leaderboard-header-bg.png"

const BackgroundHead = {
    backgroundImage: 'url(' + BackgroundHeader + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

function Expandable({ userId, contestId }) {

    const { isLoading, isError, data: leaderboard, error, refetch } = useQuery(`leaderboard-contests-${contestId}-${userId}`, async () => {

        try {
            const response = await ApiService.get(Endpoints.LEADERBOARDCONTESTS(contestId));
            if (response.status) {
                console.log("alter enter", response);
                return response.data.leaderboard || [];
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }
    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })
    // console.log("leaderboard", leaderboard);

    return (
        <>
            <div style={BackgroundHead} className="text-left leaderTitle px-5 py-3 row h-[40px]">
                <div className='w-6/12 column'>TEAM NAME</div>
                <div className=' column w-3/12 text-center'>POINTS</div>
                <div className=' column w-3/12 text-center'>RANK</div>
            </div>
            {isLoading ? <div className='w-full text-center'>
                <CustomizeLoader className="mt-16 " size={65}></CustomizeLoader>
            </div> : <div className='w-full mt-2'>
                <div className="flex flex-col">
                    {
                        leaderboard.length <= 0 ? <div className='flex flex-col items-center justify-center h-screen'>
                            {AppString.LEADERBOARDNOTFOUND}
                        </div> : leaderboard.map((e) => {
                            return <details className="group">
                                <summary className="block justify-between items-center font-medium cursor-pointer list-none px-5  py-3 group-open:bg-transparent">
                                    <div className="row">
                                        <div className="flex items-center mb-2 w-6/12 column">
                                            <span className="font-bold text-white">{e.user_name}</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="flex items-center justify-between mb-2 column w-3/12">
                                            <span className="font-bold text-white m-auto">{e.total_points}</span>
                                        </div>
                                        <div className="flex items-center justify-between mb-2 column w-3/12">
                                            <span className="font-bold text-white m-auto">{e.rank_position}</span>
                                        </div>
                                    </div>
                                </summary>
                                <p className="text-neutral-600 group-open:animate-fadeIn px-5">
                                    <div className='flex flex-col'>
                                        <div className="row">
                                            {e.team_player_image.map((img) => <img className='leaderimg column' src={img}></img>)}

                                            {/* <img className='leaderimg column' src={images.playerImage}></img>
                                            <img className='leaderimg column' src={images.playerImage}></img> */}
                                        </div>
                                    </div>
                                </p>
                            </details>
                        })
                    }

                </div>
            </div>}
        </>
    )
}

export default Expandable