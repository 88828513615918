import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ContestMatchCard from '../components/contest_match_card';
import images from "../helper/images";
import PointsSystemModal from '../components/modal/pointSystemModal';
import CountdownTimer from "../components/CountdownTimer";
import { useParams } from 'react-router-dom';
import {
    useQuery,
} from 'react-query'
import ApiService from "../ApiService";
import Endpoints from "../Endpoints";
import AppString from "../appstring";
import CustomizeLoader from "../components/custom_loading/custom_loader";
import { cacheTime, staleTime } from '../helper/common';

import teamBg from "../assets/img/result_header_bg.png"


const TeamHead = {
    backgroundImage: 'url(' + teamBg + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '100%',
    height: "100%"
}


const ContestMatchScreen = () => {
    const { matchId } = useParams();
    const { isLoading: isContestLoading, isError: isContestError, data: contestData, error: errorData } = useQuery(`joined_contest_screen_${matchId}`, async () => {

        try {
            console.log("API", Endpoints.GETCONTESTS + `?match_id=${matchId}`);
            const response = await ApiService.get(Endpoints.GETCONTESTS + `?match_id=${matchId}`);
            console.log("join contest res", response)
            if (response.status) {
                return response.data;
            }
            return null
        } catch (error) {
            return null
        }
    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })

    const history = useNavigate();

    const handleGoBack = () => {
        history(-1)
    }
    const [isOpen, setIsOpen] = useState(false)


    function renderStatus(status, timer, statusId) {
        if (statusId === 3) {
            return <div className='grey_bg w-max rounded-full backdrop-blur-[72px] flex justify-center items-center px-1.5 py-1 mt-3'>
                <div className='mbg-red w-2.5 h-2.5 mr-1 rounded-[10px]'></div>
                <span className='text-white text-sm font-semibold'>Live</span>
            </div>
        } else if (statusId === 2) {
            return <div style={{
                paddingBottom: '8px',
            }}>
                <span className=' px-1.5 py-1  mt-3 mbg-green text-xs text-white font-bold' style={{
                    borderRadius: '8px',
                }}>COMPLETED</span>
            </div>

        } else if (statusId === 1) {
            return <div className='glass-bg rounded-[10px] px-5 flex flex-col justify-center items-center py-1.5 mt-3'>
                <h6 className='font-sans text-xs text-white font-normal '>STARTS IN</h6>
                <h5 className='text-sm text-white font-bold'><CountdownTimer targetDate={timer} /> </h5>
            </div>
        }
        else if (status === 4) {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>{status}</span>
        }
        else {
            return <div></div>

        }
    }

    return (
        <div>
            {isOpen && <PointsSystemModal isOpen={isOpen} setIsOpen={setIsOpen}></PointsSystemModal>}
            <div style={{ height: '50px', display: 'flex', justifyContent: 'space-between', paddingLeft: "8px", paddingRight: "8px" }} className='header-bg flex justify-center items-center py-8'>
                <img onClick={handleGoBack} className='w-[28px] h-[28px] ' src={images.back} alt="" />
                <h2 style={{ fontSize: '24px' }} className='text-white text-2xl font-bold'>
                    Your Contests</h2>
                <button onClick={() => {
                    setIsOpen(true)
                }} >
                    <img className='w-[28px] h-[28px] ' src={images.pointsIcon} alt="" />
                </button>
            </div>
            {isContestLoading ?
                <div>
                    <CustomizeLoader className="mt-16" size={65}></CustomizeLoader>
                </div> :
                <div>
                    <div style={TeamHead}>
                        {
                            !isContestLoading &&
                            <div className=' px-[25px] py-3 flex justify-between '>
                                <div className='flex flex-row justify-center'>
                                    <img className='w-[45px] h-[45px] rounded-[45px]' src={contestData.match.t1_logo} alt="" />
                                    <div className='flex flex-col justify-left items-left'>
                                        <h4 className='text-white text-left text-base font-bold ' style={{ paddingLeft: '10px' }} >{contestData.match.t1_short_name}</h4>
                                        {contestData.match.t1_score != null || contestData.match.t1_over != null ? <div className='flex items-center' style={{ paddingLeft: '10px' }}>

                                            <h4 className='text-white text-xl font-bold text-center' >{contestData.match.t1_score}</h4>
                                            <span className='font-sans text-white text-xs font-normal text-center pr-1' style={{ paddingLeft: '6px' }}> ({contestData.match.t1_over})</span>

                                        </div> : <div className='flex items-center' style={{ paddingLeft: '10px' }}>

                                            <span className='font-sans text-white text-xs font-normal text-center  h-[27px]'>yet to Start</span>

                                        </div>}
                                    </div>


                                </div>

                                <div className='flex flex-row' style={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start'
                                }}>
                                    {renderStatus(contestData.match.status_str, contestData.match.start_time, contestData.match.status)}
                                </div>

                                <div className='flex flex-row justify-center'>

                                    <div className='flex flex-col justify-left items-left'>
                                        <h4 className='text-white text-right text-base font-bold ' style={{ paddingRight: '10px' }} >{contestData.match.t2_short_name}</h4>
                                        {contestData.match.t2_score != null || contestData.match.t2_over != null ? <div className='flex items-center' style={{ paddingRight: '10px' }}>
                                            <span className='font-sans text-white text-xs font-normal text-center pr-1' style={{ paddingRight: '6px' }}> ({contestData.match.t2_over})</span>

                                            <h4 className='text-white text-xl font-bold text-center' >{contestData.match.t2_score}</h4>
                                        </div> : <div className='flex items-center' style={{ paddingRight: '10px' }}>
                                            <span className='font-sans text-white text-xs font-normal text-center pl-1  h-[27px]'>yet to Start</span>

                                        </div>}
                                    </div>
                                    <img className='w-[45px] h-[45px] rounded-[45px]' src={contestData.match.t2_logo} alt="" />
                                </div>


                            </div>}

                        {
                            !isContestLoading && contestData.match.status === 2 ?
                                <div className='flex  justify-center'>

                                    <span style={{
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        padding: '8px', color: 'white', background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)', fontFamily: 'Product Sans',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '17px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                    }}>
                                        {contestData.match.match_desc}
                                    </span>
                                </div> : <div></div>
                        }

                    </div>

                    <div>
                        <div className='flex flex-col justify-center mt-[25px]'>
                            {
                                !isContestLoading && contestData.contests.length <= 0 ? <div className='flex flex-col items-center justify-center h-screen'>
                                    {AppString.CONTESTNOTFOUND}
                                </div> :
                                    contestData && contestData.contests.map((e, i) => <ContestMatchCard key={i + 'yourContest'} item={e}>

                                    </ContestMatchCard>)
                            }
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default ContestMatchScreen;