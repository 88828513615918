import React from 'react';
import { useNavigate } from "react-router-dom";
import MyMatchesCard from '../components/myMatchesCard';
import images from "../helper/images";
import ApiService from "../ApiService";
import Endpoints from "../Endpoints";
import {
    useQuery,
} from 'react-query'
import CustomizeLoader from '../components/custom_loading/custom_loader';
import { cacheTime, staleTime } from '../helper/common';





const MyMatchScreen = () => {
    // let data = [];
    // data = fakeMatches;
    const history = useNavigate();
    const handleGoBack = () => {
        history(-1)
    }


    const { isLoading: isLoading, isError, data: data, error } = useQuery('home_my_matches', async () => {

        try {
            const response = await ApiService.get(Endpoints.MYMATCHES);
            if (response.data) {
                return response.data;
            }
            console.log("afrith match:", response.data.live);
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }

    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })
    return (
        <div>

            <div style={{ height: '50px', display: 'flex', justifyContent: 'space-between', paddingLeft: "8px", paddingRight: "8px" }} className='header-bg flex justify-center items-center py-8'>
                <img onClick={handleGoBack} className='w-[30px] h-[30px] ' src={images.back} alt="" />
                <h2 style={{ fontSize: '24px' }} className='text-white text-2xl font-bold'>
                    My Matches</h2>
                <div style={{ width: '5px', display: 'flex', justifyContent: 'space-between' }} className=' flex justify-center items-center py-8'>
                </div>
            </div>
            {isLoading ? <CustomizeLoader size={65}> </CustomizeLoader> :
                <div>
                    <div className='flex flex-col justify-center px-[16px]  '>
                        {(!isLoading && data.matches.live.length > 0) &&
                            <details className="group" open>
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-all  py-3 mb-2">
                                    <div className='flex flex-col'>
                                        <span className='text-white text-base font-semi-bold'> Live Match</span>
                                    </div>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                {
                                    data.matches.live?.map((e, i) => <MyMatchesCard key={i} item={e} ></MyMatchesCard>)}
                            </details>
                        }
                        {(!isLoading && data.matches.completed.length > 0) && <details className="group" open>
                            <summary className="flex justify-between items-center font-medium cursor-pointer list-all  py-3 mb-2">
                                <div className='flex flex-col'>
                                    <span className='text-white text-base font-semi-bold'> Completed Match</span>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                                    </svg>
                                </span>
                            </summary>
                            {
                                data.matches.completed?.map((e, i) => <MyMatchesCard key={i} item={e} ></MyMatchesCard>)}
                        </details>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default MyMatchScreen;