import React, { useState } from 'react'
import ApiService from '../ApiService';
import AppString from '../appstring';

import Endpoints from '../Endpoints';
import { cacheTime, staleTime } from '../helper/common';


import {
    useQuery,
} from 'react-query';
import CustomizeLoader from './custom_loading/custom_loader';
import BackgroundHeader from "../assets/img/leaderboard-header-bg.png"

const BackgroundHead = {
    backgroundImage: 'url(' + BackgroundHeader + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

function Winnings({ contestId }) {

    const { isLoading, isError, data: winningBreakup, error } = useQuery(`leaderboard-winnings-${contestId}`, async () => {

        try {
            const response = await ApiService.get(Endpoints.WINNINGSBREAKUP + `?contest_id=${contestId}`);

            if (response.status) {
                return response.data.winning_breakup || [];
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }
    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })

    console.log("winningBreakup", winningBreakup);


    return (
        <div>
            <div style={BackgroundHead} class="text-left leaderTitle px-5 py-3 row h-[40px]">
                <div class='w-6/12 column'>RANK</div>
                <div class=' column w-3/12 text-center'>EARNINGS</div>
                <div class=' column w-3/12 text-center'>BONUS</div>
            </div>
            {
                isLoading ? <div className='w-full text-center'>
                    <CustomizeLoader className="mt-16 " size={65}></CustomizeLoader>
                </div> : <div className='w-full mt-2'>
                    <div class="flex flex-col">
                        {
                            winningBreakup.length <= 0 ? <div className='flex flex-col items-center justify-center h-screen'>
                                {AppString.WINNINGSNOTFOUND}
                            </div> : winningBreakup.map((e, i) => {
                                return (
                                    <div key={"winnings4234" + i} class="block justify-between items-center font-medium cursor-pointer list-none px-5 py-3">
                                        <div className="row">
                                            <div className="flex items-center mb-2 w-6/12 column">
                                                <span className="font-bold text-white">{e.rank}</span>
                                            </div>
                                            <div className="flex items-center justify-between mb-2 column w-3/12">
                                                <span className="font-bold text-white m-auto">{e.winnings}</span>
                                            </div>
                                            <div className="flex items-center justify-between mb-2 column w-3/12">
                                                <span className="font-bold text-white text-center m-auto">{e.bonus_winnings || '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Winnings