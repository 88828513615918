// src/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import JoinMatchScreen from './screen/join_match_screen';
import UpcomingMatchScreen from './screen/upcoming_match_screen';
import MyMatchScreen from './screen/my_match_screen';
import SelectCaptain from './screen/select_captain';
import {useLayoutEffect} from 'react';


import NotFound from './components/NotFound';
import SelectTeamScreen from './screen/select_team_screen';
import LeaderBoardScreen from './screen/leaderboard_screen';
import ContestMatchScreen from './screen/contest_match_screen';

//Scroll to top
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const AppRouter = () => {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/join_match_screen/:id" element={<JoinMatchScreen />} />
          <Route path="/up_coming_match_screen" element={<UpcomingMatchScreen />} />
          <Route path="/my_match_screen" element={<MyMatchScreen />} />
          <Route path="/select-team/:matchId/:contestId" element={<SelectTeamScreen />} />
          <Route path="/leaderboard/:matchId/:contestId" element={<LeaderBoardScreen />} />
          <Route path="/contest_match_screen/:matchId" element={<ContestMatchScreen />} />
          <Route path="/select_captain" element={<SelectCaptain />} />
          <Route element={<NotFound />} />
        </Routes>
      </Wrapper>
    </Router>
  );
};

export default AppRouter;
