import React from 'react';
import { useNavigate } from "react-router-dom";
import UpcommingMatchesCard from '../components/upcommingMatch';
import fakeMatches from "../assets/mockData/my_matches.json";
import images from "../helper/images";
import {
    useQuery,
} from 'react-query'

import Endpoints from '../Endpoints';
import ApiService from "../ApiService";
import { cacheTime, staleTime } from '../helper/common';






const UpcomingMatchScreen = () => {
    // let data = [];
    // data = fakeMatches.upcoming;
    const history = useNavigate();
    const { isLoading, isError, data, error } = useQuery('all-upcomming-matches', async () => {

        try {
            const response = await ApiService.get(Endpoints.GETMATCHES);
            if (response.data.matches) {
                return response.data.matches;
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }

    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })
    const handleGoBack = () => {
        history(-1)
    }
    return (
        <div>


            <div style={{ height: '50px', display: 'flex', justifyContent: 'space-between', paddingLeft: "8px", paddingRight: "8px" }} className='header-bg flex justify-center items-center py-8'>
                <img onClick={handleGoBack} className='w-[30px] h-[30px] ' src={images.back} alt="" />
                <h2 style={{ fontSize: '24px' }} className='text-white text-2xl font-bold'>


                    Upcoming Matches</h2>

                <div style={{ width: '5px', display: 'flex', justifyContent: 'space-between' }} className=' flex justify-center items-center py-8'>

                </div>
            </div>
            <div style={{ width: '5px', display: 'flex', justifyContent: 'space-between' }} className=' flex justify-center items-center py-8'>

            </div>
            <div>
                <div className='flex flex-col justify-center px-[25px]  '>
                    {
                        isLoading ? <h5>Loading....</h5> :
                            data && data?.map((e) => <UpcommingMatchesCard item={e} ></UpcommingMatchesCard>)
                    }
                </div>
            </div>

        </div>
    );
};

export default UpcomingMatchScreen;