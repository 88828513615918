
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Carousel from "../components/carousel/Carousel";
import Card from "../components/carousel/Card";
import images from '../helper/images'
import fakeMatches from "../assets/mockData/my_matches.json";
import CountdownTimer from "../components/CountdownTimer";
import ApiService from "../ApiService";
import Endpoints from "../Endpoints";
import CustomizeLoader from "../components/custom_loading/custom_loader";
import ErrorPOPUP from "../components/popup_status/error_popup";
import SucessPOPUP from "../components/popup_status/sucess_popup";
import { delay } from "../helper/common";


const SelectCaptain = ({ cards, team, contestId, finalTeam, team_id }) => {
    const history = useNavigate();
    const [playerIndex, setPlayerIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const proceed = () => {
        console.log("c player", cards, playerIndex, cards[playerIndex].key);
        let finalTeamSubmit = {
            contest_id: contestId,
            team: finalTeam,
            captain_id: cards[playerIndex].key,
            user_team_id: team.user_team_id
        }
        console.log("finalTeamSubmit", JSON.stringify(finalTeamSubmit));
        submitTeam(finalTeamSubmit);
    }

    async function submitTeam(finalTeamSubmit) {
        try {
            setLoading(true);
            const response = await ApiService.post(Endpoints.SAVETEAM, finalTeamSubmit);
            console.log("save team res", response)
            if (response.status) {
                setLoading(false);
                setSuccess(response.message);
                await delay(3);
                history(`/`, { replace: true })
            } else {
                setLoading(false);
                setError(response.message);
                console.log(response);
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
            setError(error.message);
            return false
        }
    }

    const handleGoBack = () => {
        history(-1)
    }

    function renderStatus(status, endTime) {
        if (status == "live") {
            return <div className='grey_bg w-max rounded-full backdrop-blur-[72px] flex justify-center items-center px-1.5 py-1 mt-3'>
                <div className='mbg-red w-2.5 h-2.5 mr-1 rounded-[10px]'></div>
                <span className='text-white text-sm font-semibold'>Live</span>
            </div>
        } else if (status == "completed") {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>Completed</span>

        } else if (status == "upcoming" || status == "Scheduled") {
            return <div className='glass-bg rounded-[10px] px-5 flex flex-col justify-center items-center py-1.5 mt-3'>
                <h6 className='font-sans text-xs text-white font-normal '>STARTS IN</h6>
                <h5 className='text-base text-white font-bold'><CountdownTimer targetDate={endTime} /></h5>
            </div>
        } else {
            <div></div>
        }
    }

    return (
        <div>
            {loading && <CustomizeLoader></CustomizeLoader>}
            {success && <SucessPOPUP content={success} enableClose={false} iopen={true}></SucessPOPUP>}
            {error && <ErrorPOPUP content={error} open={true} onClose={() => setError(null)}></ErrorPOPUP>}
            {!loading && <div className='relative'>
                <div style={{ height: '50px', paddingLeft: "8px", paddingRight: "8px" }}
                    className='header-bg flex items-center py-8'>
                    <img onClick={handleGoBack} className='w-[30px] h-[30px] ' src={images.back} alt="" />
                    <h2 className='text-white text-center text-2xl font-bold'>
                        Select Team
                    </h2>
                </div>
                <div className=' w-full h-[90px] relative'>
                    <div className="bg-[url('./assets/img/select_team_match_bg.png')] absolute h-[90px] w-full"></div>

                    <div className=' px-[25px] py-3 flex w-full justify-between items-center bg-transparent absolute'>
                        <div className='flex items-center justify-center'>
                            <img className='w-[46px] h-[46px] rounded-[36px]' src={team.match.t1_logo} alt="" />
                            <h4 className='text-white text-center text-base font-bold ml-2'>{team.match.t1_short_name}</h4>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            {renderStatus(team.match.status_str, team.match.start_time)}
                        </div>
                        <div className='flex items-center justify-center'>
                            <h4 className='text-white text-center text-base font-bold pt-[5px]  mr-2'>{team.match.t2_short_name}</h4>
                            <img className='w-[46px] h-[46px] rounded-[36px]' src={team.match.t2_logo} alt="" />
                        </div>
                    </div>
                </div>

                <Carousel
                    cards={cards}
                    height="330px"
                    width="96%"
                    margin="0 auto"
                    offset={2}
                    showArrows={false}
                    playerIndex={setPlayerIndex}
                />
                <img className='w-full mt-[-30px]' src={images.union} alt="" />
                <img className='w-[50px] m-auto mt-[10px]' src={images.captain_badge} alt="" />
                <div onClick={proceed} className='flex justify-center'>
                    <button className={`rounded-full w-[50%] text-black text-base font-bold px-4 py-2 mt-5 mb-6 bg-white  disabled:opacity-75 `}>
                        SUBMIT TEAM
                    </button>
                </div>
            </div>}
        </div>
    );
};

export default SelectCaptain;