import React from 'react'
import { useNavigate } from "react-router-dom";
import CountdownTimer from "./CountdownTimer";


function UpcommingMatchesCard({ item }) {
    const navigate = useNavigate();
    const handleMenuClick = () => navigate(`/join_match_screen/${item.id}`, { replace: false });
    function renderStatus(status, timer, statusId) {
        if (statusId === 3) {
            return <div className='grey_bg w-max rounded-full backdrop-blur-[72px] flex justify-center items-center px-1.5 py-1 mt-3'>
                <div className='mbg-red w-2.5 h-2.5 mr-1 rounded-[10px]'></div>
                <span className='text-white text-sm font-semibold'>Live</span>
            </div>
        } else if (statusId === 2) {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>Completed</span>

        } else if (statusId === 1) {
            return <div className='glass-bg rounded-[10px] px-5 flex flex-col justify-center items-center py-1.5 mt-3'>
                <h6 className='font-sans text-xs text-white font-normal '>STARTS IN</h6>
                <h5 className='text-sm text-white font-bold'><CountdownTimer targetDate={timer} /> </h5>
            </div>
        }
        else if (status === 4) {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>{status}</span>
        }

        else {
            <div></div>
        }
    }

    return (
        <div onClick={handleMenuClick} className='bg-card-bg bg-cover rounded-[10px] w-full mb-[20px]'>

            <div className='px-[19px] py-[19px] flex justify-between items-center'>
                <div className='flex flex-col justify-center'>
                    <img className='h-[50px] rounded-[36px]' src={item.t1_logo} alt="" />
                    <h4 className='text-white text-center text-xl font-bold pt-[5px] pb-3'>{item.t1_short_name}</h4>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <h5 className='text-white text-center text-base font-bold'>{item.league_short_name}</h5>
                    {renderStatus(item.status_str, item.start_time, item.status_id)}
                </div>
                <div className='flex flex-col justify-center'>
                    <img className='h-[50px] rounded-[36px]' src={item.t2_logo} alt="" />
                    <h4 className='text-white text-center text-xl font-bold pt-[5px] pb-3'>{item.t2_short_name}</h4>
                </div>
            </div>
        </div>
    )
}

export default UpcommingMatchesCard