import React from 'react';
import { Spinner, Default } from 'react-spinners-css';
import { color } from '@mui/system';

const CustomizeLoader = ({ size: size, className }) => {
    return (
        <div className='flex flex-col items-center justify-center h-screen' >
            <Default color='#6F82FF' size={size} className={`${className} m-auto`}  ></Default>
        </div>
    );
};



export default CustomizeLoader;