const Endpoints = {
  GETMATCHES: "app/match", // Replace with the actual endpoint for fetching data
  // Add more endpoints here as needed
  GETCONTESTS: "app/contest",
  POINTSYSTEM: "app/points-system",
  MATCHPLAYERS: "app/match-players",
  TEAM: (match_id, contest_id) => `bowled/team-players?match_id=${match_id}&contest_id=${contest_id}`,
  LISTEDPLAYER: (player_id, player_name) => `bowled/listed-players?playerId=${player_id}&playerName=${player_name}`,
  WINNINGSBREAKUP: "app/contest-winning-breakup",
  STATLIST: "app/stat-list",
  SCOREBOARD: "app/score-card",
  PLAYERSTATS: "app/player-stat",
  MYMATCHES: "app/joined-matches",
  SAVETEAM: "app/bowled/user-team",
  LEADERBOARDCONTESTS: (contest_id) => `app/contest-leaderboard?contest_id=${contest_id}`,
  SAVETEAM: "bowled/user-team",
  BUYCARD: (userNftId) => `bowled/buy-player-card?userNftId=${userNftId}`,
  // SAVETEAM: "bowled/user-team",
  JOINEDCONTEST: (match_id) => `app/joined-contests?match_id=${match_id}`,
  CONTESTLEADERBOARD: (contest_id) => `app/contest-leaderboard?contest_id=${contest_id}`,
  CONTESTINFO: (contest_id) => `app/contest-info?contets_id=${contest_id}`,
  USER: "auth/user"

};

export default Endpoints;
