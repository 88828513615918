import React, { useState } from 'react'
import Modal from '../modal';
import images from '../../helper/images';
import SwipeableButton from '../swipeButton';
import Endpoints from "../../Endpoints";
import { useQuery } from 'react-query';
import ApiService from "../../ApiService";
import LoadingPOPUP from "../popup_status/loading_popup";
import CustomizeLoader from "../custom_loading/custom_loader";
import ErrorPOPUP from "../popup_status/error_popup";
import SucessPOPUP from "../popup_status/sucess_popup";
import { cacheTime, staleTime } from '../../helper/common';

function CardBuyModal({ isOpen, setIsOpen, playerId, playerName }) {
    var [selectedIndex, setSelectedIndex] = useState(0);
    var [errorMessage, setErrorMessage] = useState(null);
    var [isError, setisError] = useState(false);
    var [buy, setBuy] = useState(false);
    var [buySuccess, setBuySuccess] = useState(false);

    const { isLoading, isError: player_error, data: players, error } = useQuery(`select-player-list-${playerId}`, async () => {
        try {
            console.log("player", isOpen, playerId, playerName)
            const response = await ApiService.get(Endpoints.LISTEDPLAYER(playerId, playerName));
            // const response = await ApiService.get(Endpoints.LISTEDPLAYER(473, "Virat Kohli"));
            console.log("player listed data", response)
            if (response.status) {
                return response.data;
            } else {
                setErrorMessage(response.message);
                setisError(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setErrorMessage(error.message);
            setisError(true);
        }

    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })

    async function buyCard() {
        try {
            setBuy(true);
            const response = await ApiService.post(Endpoints.BUYCARD(players.listedCards[selectedIndex].userNftId), {});
            // const response = await ApiService.get(Endpoints.LISTEDPLAYER(473, "Virat Kohli"));
            console.log("buy card res", response)
            if (response.status) {
                setBuy(false);
                setBuySuccess(true);
                return response.data;
            } else {
                setBuy(false);
                setErrorMessage(response.message);
                setisError(true);
                console.log("error message", errorMessage, response)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setErrorMessage(error.message);
            setisError(true);
        }
    }

    return (
        <div>
            {isLoading && <CustomizeLoader></CustomizeLoader>}
            {buy && <LoadingPOPUP enableClose={false} iopen={true} />}
            {buySuccess && <SucessPOPUP enableClose={false} iopen={true} />}
            {errorMessage && <ErrorPOPUP content={errorMessage} open={true} onClose={() => setErrorMessage(null)} />}
            {errorMessage && (
                <div className="w-1/2 m-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">{errorMessage}</strong>
                    {/* <span className="block sm:inline">Something seriously bad happened.</span> */}
                    <span onClick={() => setErrorMessage(null)} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                    </span>
                </div>
            )}
            {
                players != null && <Modal isOpen={isOpen} onClose={() => {
                    setIsOpen(false)
                }}>
                    {<div>
                        <div className='w-full grid grid-cols-[0.6fr_1fr]'>
                            <img src={players.playerImage} className='w-[98px] h-[150px] absolute top-[-44px]' alt="" />
                            <div></div>
                            <div className='flex flex-col items-start'>
                                <h1 className='text-white text-center text-[26px] font-bold'>{playerName}</h1>
                                <div>
                                    <span className='rounded-full  text-white text-xs bg-[#8100AC] font-normal px-3 py-1.5 mx-1'>{players.teamName}</span>
                                    <span className='rounded-full  text-white text-xs bg-[#AC5D00] font-normal px-3 py-1.5 mx-1'>{players.role}</span>
                                </div>
                            </div>
                        </div>
                        <div className='relative mt-6'>
                            <div className='w-full grid grid-cols-[1fr_1fr_1fr]'>
                                <div></div>
                                <div className='glassmorphism-bg py-1 flex justify-center  rounded-t-md mx-1'>Sort by : </div>
                                <div className='mbg-members py-1 flex justify-center rounded-t-md  ml-1'>
                                    <h6 className='font-sans text-center text-white text-base font-normal'>
                                        {players.totalListedCards} Available
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                {
                                    players.listedCards.map((e, i) =>
                                        <div key={"listplayercard" + i} onClick={() => {
                                            setSelectedIndex(i)
                                        }} className={`  px-2 py-1 w-full grid grid-cols-[0.9fr_0.9fr_1fr_1fr] ${i % 2 == 0 ? " grey_bg  backdrop-blur-[72px]" : ""}`}>
                                            <h1 className='text-white text-center text-base font-bold'>#{e.userNftId}</h1>
                                            <h1 className='font-sans text-center text-white text-base font-normal truncate'>{e.sellerName}</h1>
                                            <div className='flex justify-center items-center'>
                                                <img src={images.xpPoints} className='w-[22px] h-[22px] mr-0.5' alt="" />
                                                <h5 className='text-white text-base font-bold'>{e.xp || 0}</h5>
                                            </div>
                                            <div className='flex justify-center items-center'>
                                                <div className={`w-[15px] h-[15px] mr-2.5 border-[1.821px] border-solid border-[#8D8D8D] rounded-xl ${selectedIndex == i ? " bg-[#00E1ff]" : ""}`}>
                                                </div> <img src={images.blueTick} className='w-[15px] h-[15px] mr-0.5' alt="" />
                                                <h5 className='text-white text-base font-bold'>{e.price}</h5>
                                            </div>
                                        </div>)
                                }
                            </div>
                        </div>
                        <div>
                            {players.totalListedCards > 0 && <SwipeableButton onSuccess={buyCard}></SwipeableButton>}
                        </div>

                    </div>}

                </Modal>}
        </div>
    )
}

export default CardBuyModal