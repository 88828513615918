import React, { useState } from 'react'
import images from '../helper/images';
import ApiService from '../ApiService';
import AppString from '../appstring';
import Endpoints from '../Endpoints';
import CustomizeLoader from './custom_loading/custom_loader'
import {
    useQuery,
} from 'react-query';
import { cacheTime, staleTime } from '../helper/common';
// import CustomizeLoader from './custom_loading/custom_loader';

import BackgroundHeader from "../assets/img/leaderboard-header-bg.png"

const BackgroundHead = {
    backgroundImage: 'url(' + BackgroundHeader + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

export default function Scorecard({ matchId }) {

    const { isLoading, isError, data: scoreBoard, error, refetch } = useQuery(`leaderboard-scoreboard-${matchId}`, async () => {

        try {
            const response = await ApiService.get(Endpoints.SCOREBOARD + `?match_id=${matchId}`);
            if (response.status) {
                console.log("score card", response);
                return response.data.innings || [];
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }
    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })

    console.log("scoreBoard", scoreBoard);
    return (
        <div>
            {isLoading && <CustomizeLoader></CustomizeLoader>}
            <div className='w-full mt-2'>
                {
                    isLoading ? <div className='w-full text-center'>
                        <CustomizeLoader className="mt-16 " size={65}></CustomizeLoader>
                    </div> : <div class="flex flex-col">
                        {
                            scoreBoard.length <= 0 ? <div className='flex flex-col items-center justify-center h-screen'>
                                {AppString.STATSNOTFOUND}
                            </div> : scoreBoard.map((e, i) => {
                                return <details key={"scoreboard13221" + i} class="group leaderboard-accordion">
                                    <summary class="block justify-between items-center font-medium cursor-pointer list-none px-5 bg-white/10 py-3">
                                        <div className="w-full flex items-center justify-between">
                                            <div className="flex items-center mb-2">
                                                <span className="font-bold text-white text-base">{e.innings.bat_team}</span>
                                                <span className="font-bold text-white rounded-[10px] px-[9px] ml-[15px] inBadge text-[12px]">{e.innings.innings}<small>nd</small> Innings</span>
                                            </div>
                                            {/* <div className="flex items-center justify-between mb-2 column w-3/11">
                                            <span className="font-bold text-white m-auto">{e.points}</span>
                                        </div> */}
                                            <div className="flex items-center justify-between mb-2 ">
                                                <span className="font-semibold text-white text-[16px] m-auto">{e.innings.scores_full.split(" ")[0]}<span class='text-[12px] font-normal ml-1'>({e.innings.scores_full.split("(")[1]}</span></span>
                                                <span class="transition group-open:rotate-180 ml-[15px]">
                                                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </summary>
                                    <p class=" group-open:animate-fadeIn">
                                        <div className='flex flex-col'>
                                            <div>
                                                <div style={BackgroundHead} class="text-white text-[14px] font-semibold  px-5 py-3 row h-[40px] grid grid-cols-8">
                                                    <div class='col-span-3'>BATTER</div>
                                                    <div className='text-center'>R</div>
                                                    <div className='text-center'>B</div>
                                                    <div className='text-center'>4S</div>
                                                    <div className='text-center'>6S</div>
                                                    <div className='text-center'>S/R</div>
                                                </div>
                                                {e.batters.map((p, i) => {
                                                    return (
                                                        <div key={"batters12" + i} class="text-[14px] font-semibold text-left text-white px-5 py-3 row h-[40px] bg-transparent grid grid-cols-8 mb-[20px]">
                                                            <div class='col-span-3'>
                                                                <div>
                                                                    {p.player_name}
                                                                </div>
                                                                <div class="text-[12px] gray">
                                                                    {p.how_out}
                                                                </div>
                                                            </div>
                                                            <div className='text-center'>{p.runs}</div>
                                                            <div className='text-center'>{p.balls}</div>
                                                            <div className='text-center'>{p.fours}</div>
                                                            <div className='text-center'>{p.sixs}</div>
                                                            <div className='text-center'>{p.strike_rate}</div>
                                                        </div>)
                                                })}
                                            </div>
                                            <div>
                                                <div style={BackgroundHead} class="text-white text-[14px] font-semibold glassmorphism-bg px-5 py-3 row h-[40px] grid grid-cols-8 mt-3">
                                                    <div class='col-span-3'>Bowler</div>
                                                    <div className='text-center'>O</div>
                                                    <div className='text-center'>R</div>
                                                    <div className='text-center'>M</div>
                                                    <div className='text-center'>W</div>
                                                </div>
                                                {e.bowlers.map((p, i) => {
                                                    return (
                                                        <div key={"bowlers12" + i} class="text-[14px] font-semibold text-left text-white px-5 py-3 row h-[40px] bg-transparent grid grid-cols-8 mb-[12px]">
                                                            <div class='col-span-3'>
                                                                <div>
                                                                    {p.player_name}
                                                                </div>
                                                                {/* <div class="text-[12px] gray">
                                                                {p.how_out}
                                                            </div> */}
                                                            </div>
                                                            <div className='text-center'>{p.overs}</div>
                                                            <div className='text-center'>{p.runs}</div>
                                                            <div className='text-center'>{p.maidens}</div>
                                                            <div className='text-center'>{p.wickets}</div>
                                                        </div>)
                                                })}
                                            </div>
                                        </div>
                                    </p>
                                </details>
                            })
                        }

                    </div>
                }
            </div>
        </div>
    )
}