import logo from './logo.svg';
import './App.css';
import MyMatchesCard from './components/myMatchesCard';
import AppRouter from './AppRouter';
import BackgroundHeader from "./assets/img/bg.png";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
const BackgroundHead = {
  backgroundImage: 'url(' + BackgroundHeader + ')',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100vw',
  minHeight: '100vh'
}




// Create a client
const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='max-w-screen-sm m-auto' style={BackgroundHead}>
        <AppRouter />
      </div>
    </QueryClientProvider>
  );
}

export default App;
