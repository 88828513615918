// src/components/About.js
import React from 'react';

const About = () => {
    return (
        <div>
            <h3>Helllo about page</h3>
            {/* <Typography variant="h4" component="h1" gutterBottom>
                About Page
            </Typography>
            <Typography variant="body1">
                This is the About page.
            </Typography> */}
        </div>
    );
};

export default About;
