import React from 'react'
import { useNavigate } from "react-router-dom"
import WonIcon from "../assets/icon/won-icon.png"
import images from '../helper/images'
import WinningsBreakupInfo from '../components/leaderboard_popup/leaderboard_popup';
import { Link } from 'react-router-dom';


function ContestMatchCard({ item: item }) {
    const navigate = useNavigate();
    const handleClick = () => navigate(`/leaderboard/${item.match_id}/${item.contest_id}`, { replace: false });

    function getBadgeBg(type) {
        if (type === 1) {
            return "mbg-green"
        } else if (type === 3) {
            return "mbg-pro"
        } else if (type === 2) {
            return "mbg-members"
        } else {
            return "mbg-green"
        }
    }

    console.log("match item", item);
    return (
        <div className='mb-[20px]' >
            <div className=' mx-[25px] grey_bg backdrop-blur-[72px] rounded-[10px]' onClick={handleClick} >
                <div className='flex items-center justify-between p-5'>
                    <div className='flex items-center'>
                        <h2 className='text-white text-xl font-bold text-center'>{item.title}</h2>
                        <span className={`ml-1 text-xs font-bold px-2.5 py-0.5 rounded text-white ${getBadgeBg(item.contest_category_id)}`}>{item.type}</span>
                    </div>
                    {
                        item.is_rewarded ? <div className='flex items-center'>
                            <h2 className='text' style={{
                                fontFamily: 'Product Sans',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '19px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                color: '#FFFFFF99',
                                paddingRight: '10px'
                            }}>Won</h2>

                            <img className='w-[16px] h-[16px] ml-0.5' src={images.blueTick} alt="" />
                            <h2 className='text-white text-sm font-bold text-center mx-1' style={{ paddingRight: '10px' }}>{item.winnings}</h2>
                            <WinningsBreakupInfo >
                            </WinningsBreakupInfo>
                        </div>
                            : item.in_winning_zone ?
                                <div className='flex items-center'>
                                    <h2 className='text' style={{
                                        fontFamily: 'Product Sans',
                                        fontSize: '16px',
                                        fontStyle: 'italic',
                                        fontWeight: '400',
                                        lineHeight: '19px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        color: '#FFFFFF99', paddingRight: '10px'

                                    }}>
                                        In the winning zone</h2>
                                    <WinningsBreakupInfo contestId={item.contest_id}>
                                    </WinningsBreakupInfo>
                                </div> : <div></div>
                    }

                </div>
                <div className='flex items-center justify-between'>
                    {
                        item.joined_player_images && <div className='ml-5 flex items-center'>
                            {
                                item.joined_player_images.map((card, i) => {
                                    return <img key={"joincard" + i} className='w-[46px] h-[70px] mr-2.5' src={card} alt="" />
                                })
                            }
                        </div>
                    }
                    <div className='footer-bg backdrop-blur-[80px] rounded-tl-[20px] rounded-bl-[20px] flex items-center'>

                        <div className='my-3 mx-4 flex flex-col'>
                            <h2 className='text-center' style={
                                {
                                    fontFamily: 'Product Sans',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '19px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: "#FFFFFF99"

                                }
                            }>Pts</h2>
                            <div className='flex items-center'>

                                <h2 className='text-center' style={
                                    {
                                        color: "#FFFFFF",
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                    }
                                }>{item.total_points}</h2>
                            </div>
                        </div>
                        <div className='my-3 mx-4 flex flex-col'>
                            <h2 className='text-center' style={
                                {
                                    fontFamily: 'Product Sans',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '19px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    color: "#FFFFFF99"

                                }
                            }>Rank</h2>
                            <div className='flex items-center'>

                                <h2 className='text-center' style={
                                    {

                                        color: "#FFFFFF",
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',


                                    }
                                }>{item.rank}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {<h6 className='text-[rgba(255,255,255,0.60)] product-sans text-xs font-normal text-right pt-2.5 pb-2 px-4'>{item.teams_joined} Joined </h6>}


            </div>

        </div>


    )
}

export default ContestMatchCard