import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";

const getTouches = (evt) => {
    return (
        evt.touches || evt.originalEvent.touches // browser API
    );
};

export default function Carroussel(props) {
    const table = props.cards.map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
    });

    const [offsetRadius, setOffsetRadius] = useState(2);
    const [showArrows, setShowArrows] = useState(false);
    const [goToSlide, setGoToSlide] = useState(0);
    const [cards] = useState(table);
    const [xDown, setXdown] = useState(null);
    const [yDown, setYdown] = useState(null);

    useEffect(() => {
        setOffsetRadius(props.offset);
        setShowArrows(props.showArrows);
    }, [props.offset, props.showArrows]);

    const handleTouchMove = (evt) => {
        if (!xDown && !yDown) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                /* left swipe */
                setGoToSlide(goToSlide + 1);
                setXdown(null);
                setYdown(null);
                props.playerIndex(goToSlide + 1);
                // this.setState({
                //     goToSlide: goToSlide + 1,
                //     xDown: null,
                //     yDown: null
                // });
            } else {
                /* right swipe */
                setGoToSlide(goToSlide - 1);
                setXdown(null);
                setYdown(null);
                props.playerIndex(goToSlide - 1);
                // this.setState({
                //     goToSlide: goToSlide - 1,
                //     xDown: null,
                //     yDown: null
                // });
            }
        }
    };

    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        setXdown(firstTouch.clientX);
        setYdown(firstTouch.clientY);
        // this.setState({
        //     ...this.state,
        //     xDown: firstTouch.clientX,
        //     yDown: firstTouch.clientY
        // });
    };

    return (
        <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            style={{ width: props.width, height: props.height, margin: props.margin }}
        >
            <Carousel
                slides={cards}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                showNavigation={showArrows}
                animationConfig={config.gentle}
            />
        </div>
    );
}
