import React from 'react'

import WonIcon from "../assets/icon/won-icon.png"
import images from '../helper/images'
import Popup from '../components/leaderboard_popup/leaderboard_popup';
import { Link } from 'react-router-dom';


function JoinMatchesCard({ item: item, match_id }) {
    function getBadgeBg(type) {
        if (type == "FREE".toLocaleLowerCase()) {
            return "mbg-green"
        } else if (type == "PRO".toLocaleLowerCase()) {
            return "mbg-pro"
        } else if (type == "MEMBERS".toLocaleLowerCase()) {
            return "mbg-members"
        } else {
            return "mbg-green"
        }
    }

    return (
        <Link to={`/select-team/${match_id}/${item.contest_id}`}>
            <div className='mb-[20px]' >
                <div className=' mx-3 sm:mx-[25px] grey_bg backdrop-blur-[72px]  rounded-[10px]' >
                    <div className='flex items-center justify-between p-5'>
                        <div className='flex items-center'>
                            <h2 className='text-white text-xl font-bold text-center'>{item.title}</h2>
                            <span className={`ml-1 text-xs font-bold px-2.5 py-0.5 rounded text-white ${getBadgeBg(item.type.toLocaleLowerCase())}`}>{item.type.toLocaleUpperCase()}</span>
                        </div>
                        <div className='flex items-center'>
                            <img className='w-[20px] h-[20px] mr-1' src={images.firstIcon} alt="" />
                            <img className='w-[16px] h-[16px] ml-0.5' src={images.blueTick} alt="" />
                            <h2 className='text-white text-xs sm:text-sm font-bold text-center mx-1'>{item.first_prize}</h2>
                            <img className='w-[16px] h-[16px] sm:ml-2 ml-1' src={images.trophyIcon} alt="" />
                            <h2 className='text-white text-sm font-bold text-center sm:mx-2 mx-1'>{item.winners}</h2>
                            <Popup>
                            </Popup>

                        </div>
                    </div>
                    <div className='flex items-center justify-between'>
                        {
                            !item.user_joined ? <div className='footer-bg backdrop-blur-[80px] rounded-tr-[20px] rounded-br-[20px]  flex items-end'>
                                <img className='sm:w-[50px] sm:h-[50px] w-[40px] h-[40px] ml-4' src={images.cardStack} alt="" />
                                <div className='my-3 sm:mx-4 mx-2 flex flex-col'>
                                    <h2 className='font-sans text-[rgba(255,255,255,0.60)] sm:text-base text-sm font-normal  text-center'>Cards:</h2>
                                    <h2 className=' text-white sm:text-base text-sm font-bold  text-center'>{item.cards}</h2>
                                </div>
                            </div> : <div className='ml-5 flex items-center'>
                                {
                                    item.joined_player_images.map((card, i) => {
                                        return <img key={"joincard" + i} className='w-[46px] h-[70px] mr-2.5' src={card} alt="" />
                                    })
                                }
                            </div>
                        }
                        <div className='footer-bg backdrop-blur-[80px] rounded-tl-[20px] rounded-bl-[20px] flex items-center'>

                            <div className='my-3 mx-4 flex flex-col'>
                                <h2 className='font-sans text-[rgba(255,255,255,0.60)] sm:text-base text-sm font-normal  text-center'>Total Pool</h2>
                                <div className='flex items-center'>
                                    <img className='w-[21px] h-[21px] mr-1' src={images.blueTick} alt="" />
                                    <h2 className=' text-white sm:text-xl text-lg font-bold  text-center'>{item.total_prize_pool}</h2>
                                </div>
                            </div>
                            {!item.user_joined && <div className='my-3 mx-4 flex flex-col'>
                                <h2 className='font-sans text-[rgba(255,255,255,0.60)] sm:text-base text-sm font-normal  text-center'>Entry:</h2>
                                <span className="ml-1 mbg-green px-2.5 py-0.5 rounded  flex items-center  mt-1">
                                    {
                                        item.fee > 0 && <img className='w-[15px] h-[15px] mr-1' src={images.blueTick} alt="" />
                                    }
                                    <h6 className='sm:text-base text-sm font-bold text-white '>{item.fee == 0 ? "FREE" : item.fee}</h6></span>
                            </div>}
                        </div>
                    </div>
                    {<h6 className='text-[rgba(255,255,255,0.60)] text-xs font-normal text-right pt-2.5 pb-2 px-4'>{item.teams_joined} Joined </h6>}

                    {
                        item.type.toLocaleUpperCase() == "FREE" && <div className='footer-bg justify-center flex items-center backdrop-blur-[80px] rounded-br-[10px] rounded-bl-[10px]'>
                            <span className='text-white italic font-bold py-2.5 text-[12px]'>NO CARDS ARE REQUIRED TO JOIN THIS LEAGUE</span>

                        </div>
                    }
                </div>

            </div>
        </Link>

    )
}

export default JoinMatchesCard