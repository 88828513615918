import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import images from "../helper/images";

import LeaderBoardHeader from "../assets/img/result_header_bg.png"
import Expandable from '../components/expandable'
import Winnings from '../components/winnings'
import Scorecard from '../components/scorecard'
import Stats from '../components/stats'
import { useParams } from 'react-router-dom';
import CountdownTimer from "../components/CountdownTimer";
import {
    useQuery,
} from 'react-query'
import ApiService from "../ApiService";
import Endpoints from "../Endpoints";
import CustomizeLoader from "../components/custom_loading/custom_loader";
import PointsSystemModal from '../components/modal/pointSystemModal';
import { cacheTime, staleTime } from '../helper/common';


const LeaderBoardHead = {
    backgroundImage: 'url(' + LeaderBoardHeader + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '100%',
    height: "100%"
}

const LeaderBoardScreen = () => {
    const { contestId, matchId } = useParams();

    const [activeTab, setActiveTab] = useState(0);
    const [isOpen, setIsOpen] = useState(false)

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const { isLoading: isContestLoading, isError: isContestError, data: contestInfo, error: errorData } = useQuery(`contest-info-leaderboards-${contestId}`, async () => {

        try {
            console.log('afrith', contestId);
            const response = await ApiService.get(Endpoints.CONTESTINFO(contestId));
            if (response.data) {
                return response.data;
            }

            console.log('afrith', response.data);
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            isContestLoading = true;
            return []
        }

    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })



    const history = useNavigate();
    const handleGoBack = () => {
        history(-1)

    }



    // let matchId = 10;
    // let contestId = 124;
    // let userId = 6;


    function renderStatus(status, timer, statusId) {
        if (statusId === 3) {
            return <div className='grey_bg w-max rounded-full backdrop-blur-[72px] flex justify-center items-center px-1.5 py-1 mt-3' >
                <div className='mbg-red w-2.5 h-2.5 mr-1 rounded-[10px]'></div>
                <span className='text-white text-sm font-semibold'>Live</span>
            </div>
        } else if (statusId === 2) {
            return <div style={{
                paddingBottom: '30px'
            }}>
                <span className=' px-1.5 py-1  mt-3 mbg-green text-xs text-white font-bold' style={{
                    borderRadius: '8px'
                }}>COMPLETED</span>
            </div>

        } else if (statusId === 1) {
            return <div className='glass-bg rounded-[10px] px-5 flex flex-col justify-center items-center py-1.5 mt-3'>
                <h6 className='font-sans text-xs text-white font-normal '>STARTS IN</h6>
                <h5 className='text-sm text-white font-bold'><CountdownTimer targetDate={timer} /> </h5>
            </div>
        }
        else if (statusId === 4) {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>{status}</span>
        }
        else {
            return <div></div>

        }
    }

    function getBadgeBg(type) {
        if (type === 1) {
            return "mbg-green"
        } else if (type === 3) {
            return "mbg-pro"
        } else if (type === 2) {
            return "mbg-members"
        } else {
            return "mbg-green"
        }
    }

    let tabs = ["Leaderboard", "Winnings", "Scorecard", "Stats"];



    return (
        <div className='relative'>
            {isOpen && <PointsSystemModal isOpen={isOpen} setIsOpen={setIsOpen}></PointsSystemModal>}
            {isContestLoading && <CustomizeLoader className="mt-32 " size={65}></CustomizeLoader>}
            {!isContestLoading && <div style={{ height: '50px', display: 'flex', justifyContent: 'space-between', paddingLeft: "8px", paddingRight: "8px" }} className='header-bg flex justify-center items-center py-8'>
                <img onClick={handleGoBack} className='w-[30px] h-[30px] ' src={images.back} alt="" />
                <div className='inline'>
                    <h3 style={{ fontSize: '24px' }} className='inline text-white text-2xl font-bold'>
                        {contestInfo.contest.title}</h3>
                    <span className={`ml-2 px-2.5 py-0.5 rounded text-white text-2xl font-bold ${getBadgeBg(contestInfo.contest.contest_category_id)}`}>{contestInfo.contest.type}</span>
                </div>
                <div style={{ width: '45px', display: 'flex', justifyContent: 'space-between' }} className=' flex justify-center items-center py-8'>
                    <button onClick={() => {
                        setIsOpen(true);
                    }}>

                        <img className='w-[28px] h-[28px] ' src={images.pointsIcon} alt="" />
                    </button>
                    {/* <TeamList>
                    </TeamList> */}
                </div>
            </div>}
            {!isContestLoading && <div className=' w-full d-flex flex-col pb-3' style={LeaderBoardHead}>
                <div className=' px-[25px] py-3 flex w-full justify-between items-center  '>
                    <div className='flex items-center justify-center'>
                        <img className='w-[46px] h-[46px] rounded-[36px]' src={contestInfo.match.t1_logo} alt="" />
                        <div className='flex flex-col items-start ml-2'>
                            <h4 className='text-white text-center text-base font-bold '>{contestInfo.match.t1_short_name}</h4>
                            {contestInfo.match.t1_score != null || contestInfo.match.t1_over != null ? <div className='flex items-center'>
                                <h5 className='text-white text-xl font-bold text-center'>{contestInfo.match.t1_score}</h5>
                                <span className='font-sans text-white text-xs font-normal text-cente pl-1'> ({contestInfo.match.t1_over})</span>
                            </div> : <div className='flex items-center'>
                                <span className='font-sans text-white text-xs font-normal text-center pl-1  h-[27px]'> yet to Start</span>

                            </div>}
                        </div>
                    </div>
                    <div className='flex flex-col justify-center items-center'>

                        {renderStatus(contestInfo.match.status_str, contestInfo.match.start_time, contestInfo.match.status)}
                    </div>
                    <div className='flex items-center justify-center'>
                        <div className='flex flex-col items-end mr-2'>
                            <h4 className='text-white text-center text-base font-bold '>{contestInfo.match.t2_short_name}</h4>
                            {contestInfo.match.t2_score != null || contestInfo.match.t2_over != null ? <div className='flex items-center'>
                                <h5 className='text-white text-xl font-bold text-center'>{contestInfo.match.t2_score}</h5>
                                <span className='font-sans text-white text-xs font-normal text-cente pl-1'> ({contestInfo.match.t2_over})</span>
                            </div> : <div className='flex items-center'>
                                <span className='font-sans text-white text-xs font-normal text-center pl-1  h-[27px]'> yet to Start</span>
                            </div>}
                        </div>
                        <img className='w-[46px] h-[46px] rounded-[36px]' src={contestInfo.match.t2_logo} alt="" />
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                    {
                        tabs.map((e, i) => {
                            return <button onClick={() => {
                                handleTabClick(i)
                            }} key={"tabe" + i} className={`rounded-full  text-white text-sm font-bold px-3 py-1.5 mx-1 ${activeTab == i ? "bg-[#8100ac]" : "mbg-grey"}`}>{e}</button>
                        })
                    }

                </div>
            </div>}

            {!isContestLoading && <div>
                {
                    activeTab === 0 && <Expandable contestId={contestId} />
                }
                {
                    activeTab === 1 && <Winnings contestId={contestId} />
                }
                {
                    activeTab === 2 && <Scorecard matchId={contestInfo.contest.match_id} />
                }
                {
                    activeTab === 3 && <Stats matchId={contestInfo.contest.match_id} />
                }
            </div>}
        </div>
    );
};

export default LeaderBoardScreen;