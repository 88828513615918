import React from 'react'
import images from '../helper/images'
import Slider from "react-slick";
// import Slider from './slider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import PrimaryButton from './primary_button';

const XpFooter = styled.div`
//   background: linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, #D9D9D9 47.94%, rgba(217, 217, 217, 0.00) 100%);
//   opacity: 0.07999999821186066;
  padding: 2px 12px;
  margin: 10px 0px;
  margin-left:20px;
  display:flex;
  justify-content: center;
  align-items: center;
  width: max-content;
`;


function MultipleSlider({ onSelectPlayer, players }) {
    var settings = {
        dots: true,
        speed: 500,
        // slidesToShow:4,
        slidesToScroll: 3,
        // centerMode: true,
        variableWidth: true,
        customPaging: (i) => (
            <div className="custom-dots">
                <span className="dot" />
            </div>
        ),

    };
    return (
        <div className='mt-5 mb-8'>

            <Slider {...settings}>
                {
                    players.map((e, i) => {
                        return <div key={"Slider" + i} className='flex flex-col items-center justify-center mx-2.5' onClick={() => {
                            onSelectPlayer(e)
                        }} >
                            <div className='relative w-[120px] h-[180px]'>
                                <img src={e.image_url} alt='' className='absolute w-full h-full'></img>
                                {
                                    !e.owned &&
                                    <div className='flex flex-col items-center justify-center absolute left-0 right-0 top-[50%]'>
                                        <img className='w-[30px] h-[30px] scale-125' src={images.lock} alt="" />
                                        <button className={`rounded-full  text-black text-sm font-bold px-4 py-1 mx-5 bg-white `}>Buy</button>
                                    </div>
                                }
                            </div>
                            {
                                e.xp > 0 &&
                                <XpFooter className='ml-0'>
                                    <img className='w-4 h-4 mr-1' src={images.xpPoints} alt="" />
                                    <h6 className='text-sm font-bold text-white'>{e.xp}%</h6>
                                </XpFooter>
                            }
                        </div>
                    })
                }
            </Slider>

        </div>
    )
}

export default MultipleSlider