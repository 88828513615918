import React from 'react';
import Popup from 'reactjs-popup';
import './leaderboard_popup.css'
import leaderboard from "../../assets/mockData/ranks.json"
import images from '../../helper/images'
import Endpoints from '../../Endpoints';
import ApiService from '../../ApiService';

import {
    useQuery,
} from 'react-query';
import CustomizeLoader from '../custom_loading/custom_loader';
import { cacheTime, staleTime } from '../../helper/common';

export default function WinningsBreakupInfo({ contestId }) {

    const { isLoading, isError, data: winningBreakup, error, refetch } = useQuery(`leaderboard-winnings-${contestId}`, async () => {

        try {
            console.log("run Checl");
            const response = await ApiService.get(Endpoints.WINNINGSBREAKUP + `?contest_id=${contestId}`);
            console.log("response", response);
            if (response.status) {
                return response.data.winning_breakup || [];
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }
    }, {
        enabled: false,
        staleTime: staleTime,
        cacheTime: cacheTime
    })

    return <Popup
        trigger={
            <img className='w-[20px] h-[20px] ' src={images.infoIcon} alt="" />

        }
        modal
        nested
        onOpen={refetch}
    >
        {close => (
            <div className="modal">
                {/* <button className="close" onClick={close}>
                    &times;
                </button> */}
                <div className="header"> Leaderboard
                    <button className='center close_button' onClick={close} >
                        X
                    </button></div>
                {isLoading || !winningBreakup ? <div className='w-full flex justify-center'><CustomizeLoader className="mt-16 " size={65}></CustomizeLoader> </div> : <div className="content overflow-auto">
                    {winningBreakup.length == 0 ? <tr><h6 className='text-center mt-10'>No Results Found</h6></tr> : <table className="centered-table m-auto" style={{
                        borderCollapse: 'collapse',

                        justifyContent: 'center',

                    }}>

                        <tr>
                            <th style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontFamily: 'inter',
                                fontSize: '16px',
                                color: 'white',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                padding: "20px"
                            }}>Rank</th>
                            <th style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontFamily: 'inter',
                                fontSize: '16px',
                                color: 'white',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                padding: "20px"
                            }}>Winnings</th>

                        </tr>
                        {leaderboard.leaderboard.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontFamily: 'inter',
                                        fontSize: '14px',
                                        color: 'white',
                                        fontWeight: 'regular',
                                        textAlign: 'center',
                                        padding: "20px"
                                    }}>{val.Rank}</td>
                                    <td style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontFamily: 'inter',
                                        fontSize: '14px',
                                        color: 'white',
                                        fontWeight: 'regular',
                                        textAlign: 'center',
                                        padding: "20px"
                                    }}>{val.Winnings}</td>

                                </tr>
                            )
                        })}
                    </table>}
                </div>}
                <div className="actions">
                    {/* <button
                        className="button"
                        onClick={() => {
                            console.log('modal closed ');
                            close();
                        }}
                    >
                        close modal
                    </button> */}
                </div>
            </div>
        )}
    </Popup>
};