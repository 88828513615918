import React, { useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        const handleBodyScroll = (event) => {
            if (isOpen) {
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);
        document.body.style.overflow = isOpen ? 'hidden' : 'unset';
        document.body.addEventListener('scroll', handleBodyScroll, {
            passive: false,
        });

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
            document.body.style.overflow = 'unset';
            document.body.removeEventListener('scroll', handleBodyScroll);
        };
    }, [isOpen]);



    const closeModal = () => {
        onClose();
    };

    return (
        <>
            {isOpen && (
                <div className="fixed max-w-screen-sm m-auto inset-0 z-50 flex items-center justify-center">
                    <div
                        className="fixed max-w-screen-sm m-auto inset-0 bg-black opacity-[0.96]"
                        onClick={closeModal}
                    ></div>
                    <div className="relative glassmorphism-bg  rounded-[10px] w-[80%] h-[80%] p-6 animate-modal">
                        {children}
                        <button className='absolute top-4 right-0 mt-4 mr-4 center close_button' onClick={closeModal} >
                            X
                        </button>
                        {/* <button
                            className="absolute top-0 right-0 mt-4 mr-4 text-white hover:text-white-700 "
                            onClick={closeModal}
                        >
                            <svg
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
