// import { colors } from "@mui/material";
// import React from "react";

import React from 'react';
import BackgroundHeader from "../../assets/img/bg.png"
import './team_list.css'
import images from '../../helper/images'
import Popup from 'reactjs-popup';
import TeamPlayerList from "../../assets/mockData/team_players_list.json"
import ApiService from "../../ApiService";
import Endpoints from "../../Endpoints";
import {
    useQuery,
} from 'react-query'
import { cacheTime, staleTime } from '../../helper/common';




const TeamList = ({ matchID: matchID }) => {
    const { isLoading, isError, data, error } = useQuery('match-player-list', async () => {

        try {
            const response = await ApiService.get(`${Endpoints.MATCHPLAYERS}?match_id=${matchID}`);
            if (response.data) {
                return response.data;
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }

    },
        {
            staleTime: staleTime,
            cacheTime: cacheTime
        })


    return (
        <Popup
            trigger={
                <img className='w-[28px] h-[28px] ' src={images.shirtsIcon} alt="" />

            }
            modal
            nested
        >
            {close => (

                <div className="list-view"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '300px',
                        justifyContent: 'center',
                        position: "relative",
                        borderRadius: '12px',
                        height: '600px', background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)'

                    }}
                >


                    {
                        isLoading ? <h5>Loading....</h5> :
                            <div className='col' style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '300px',
                                justifyContent: 'space-evenly',
                                padding: '10px',
                                borderTopRightRadius: '12px',
                                borderTopLeftRadius: '12px',
                                height: '60px', background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)                        ',
                                color: 'white', fontSize: '24px', fontFamily: 'inter', fontWeight: 'bold'

                            }}>

                                <span>
                                    {data.players[0].teamName}
                                </span>
                                <span >
                                    {data.players[1].teamName}
                                </span>
                                <button className='close_btn' onClick={close} style={{
                                    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)',
                                    color: 'white',
                                    font: 'bold 16px inter',
                                    top: '16px',
                                    right: '10px',
                                    height: '25px',
                                    width: '25px',
                                    borderRadius: '100px',
                                    boxshadow: 'rgba(0, 0, 0, 0.41)',
                                    position: 'absolute',
                                }}>
                                    X
                                </button>

                            </div>
                    }
                    {
                        isLoading ? <h5></h5> : <div style={{ overflowY: "scroll" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', height: '490px', }}>
                                <ul>
                                    {data.players[0].players.map((item, index) => (
                                        <li style={{ padding: 8 }} key={index}>{<div className='col'
                                            style={{ display: "flex", padding: '8px', background: item.is_playing == true ? 'linear-gradient(270deg, rgba(68, 153, 90, 0.5) 0%, rgba(68, 153, 90, 0) 100%)' : 'rgba(255, 255, 255, 0.08)', height: "40px", width: "120px", borderRadius: '8px', justifyContent: 'start', alignItems: 'center', flexDirection: 'row' }}>

                                            <div style={{ display: "flex", background: 'rgba(255, 255, 255, 0.08)', height: "30px", width: "30px", borderRadius: '8px', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', background: 'rgba(60, 126, 140, 1)' }}  >
                                                <img src={item.image_url} style={{ width: '15px', height: '15px' }} />
                                            </div>

                                            <span style={{ color: 'white', paddingLeft: '8px', overflow: 'hidden', height: "40px", width: "90px", textOverflow: "ellipsis", fontSize: 18, fontFamily: 'Inter', maxHeight: "1.5em" }}>{item.player_name}</span>


                                        </div>}</li>
                                    ))}
                                </ul>
                                <ul>
                                    {data.players[1].players.map((item, index) => (
                                        <li style={{ padding: 8 }} key={index}>{<div className='col'
                                            style={{ display: "flex", padding: '8px', background: item.is_playing == true ? 'linear-gradient(270deg, rgba(68, 153, 90, 0.5) 0%, rgba(68, 153, 90, 0) 100%)' : 'rgba(255, 255, 255, 0.08)', height: "40px", width: "120px", borderRadius: '8px', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>



                                            <span style={{ color: 'white', paddingLeft: '8px', overflow: 'hidden', height: "40px", width: "90px", textOverflow: "ellipsis", fontSize: 18, fontFamily: 'Inter', maxHeight: "1.5em" }}>{item.player_name}</span>

                                            <div style={{ display: "flex", background: 'rgba(255, 255, 255, 0.08)', height: "30px", width: "30px", borderRadius: '8px', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', background: 'rgba(140, 137, 60, 1)' }}  >
                                                <img src={item.image_url} style={{ width: '15px', height: '15px' }} />
                                            </div>
                                        </div>}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    }
                    {

                        isLoading ? <h5></h5> : <div className='footer' style={{
                            display: 'flex',
                            color: 'rgba(255, 255, 255, 0.6)',
                            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)', width: '100%', height: "50px", alignItems: 'center', justifyContent: 'center', alignContent: 'center', borderBottomRightRadius: '12px',
                            borderBottomLeftRadius: '12px',
                        }}>

                            {data.isLineUpAnnounced == true ? <div className='col' style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'rgba(255, 255, 255, 0.6)',
                            }}>
                                <div style={{ background: 'rgba(69, 253, 109, 0.46)', width: '10px', height: '10px' }}></div>
                                <span style={{ color: 'rgba(255, 255, 255, 0.6)', paddingLeft: '8px' }}>
                                    Announced in the lineup
                                </span>
                            </div> : 'Lineups not announced yet'}
                        </div>

                    }

                </div>



            )
            }

        </Popup >
    );
};



export default TeamList;