import React, { useEffect } from 'react';
import CloseButton from './icon_component/closeButton';
import BackgroundHeader from "../assets/img/bg.png"
const BackgroundHead = {
    backgroundImage: 'url(' + BackgroundHeader + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

const PointModal = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        const handleBodyScroll = (event) => {
            if (isOpen) {
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);
        document.body.style.overflow = isOpen ? 'hidden' : 'unset';
        document.body.addEventListener('scroll', handleBodyScroll, {
            passive: false,
        });

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
            document.body.style.overflow = 'unset';
            document.body.removeEventListener('scroll', handleBodyScroll);
        };
    }, [isOpen]);



    const closeModal = () => {
        onClose();
    };

    return (
        <>
            {isOpen && (
                <div className="fixed max-w-screen-sm m-auto inset-0 z-50 flex items-center justify-center">
                    <div
                        className="fixed max-w-screen-sm m-auto inset-0 bg-black opacity-[0.96]"
                        onClick={closeModal}
                    ></div>
                    <div className="relative rounded-[10px]  w-[80%] h-[90%] flex items-center flex-col animate-modal" >
                        <div className='point_modal_header w-full py-4 px-3 flex justify-between  rounded-t-xl'>
                            <div></div>

                            <h1 className='text-2xl text-center text-white  font-bold'>Points System</h1>
                            <button onClick={closeModal}>
                                <CloseButton ></CloseButton>
                            </button>

                        </div>
                        {children}



                    </div>
                </div>
            )}
        </>
    );
};

export default PointModal;
