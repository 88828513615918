
import React, { useState, useEffect } from 'react';
import MultipleSlider from './slider';

function TeamSelectionTab({ tabs, onSelectPlayer, players }) {
    const [activeTab, setActiveTab] = useState(0);
    const [filteredList, setFilteredList] = useState(players);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    // useEffect(() => {
    //     var filteredData = playerList(players);
    //     // setFilteredList(filteredData);
    // },
    //     [filteredList]);

    function playerList(iplayers) {
        const filteredPlayers = iplayers.filter(
            (player) => player.role == tabs[activeTab] || tabs[activeTab] == "ALL"
        );
        return filteredPlayers;
    }

    return (
        <div>
            <div className='flex items-center justify-center m-5'>
                {
                    tabs.map((item, index) => {
                        return <button onClick={() => {
                            handleTabClick(index)
                        }} key={index}
                            className={`rounded-full  text-white text-sm font-bold px-3 py-1.5 mx-1 
                        ${activeTab == index ? "bg-[#8100ac]" : "mbg-grey"}`}>{item}</button>
                    })
                }

            </div>
            <div className='mt-5 ml-4'>
                <MultipleSlider players={playerList(players)} onSelectPlayer={onSelectPlayer}></MultipleSlider>
            </div>
        </div>
    )
}

export default TeamSelectionTab