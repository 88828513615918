import React from 'react'

function DownArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.2288 3.33154C10.5452 3.33154 10.8067 3.56667 10.8481 3.87173L10.8538 3.95654L10.8538 16.4565C10.8538 16.8017 10.574 17.0815 10.2288 17.0815C9.91238 17.0815 9.65088 16.8464 9.6095 16.5414L9.60379 16.4565L9.60379 3.95654C9.60379 3.61136 9.88361 3.33154 10.2288 3.33154Z" fill="white" />
            <path d="M14.8058 10.9738C15.0494 10.7292 15.4451 10.7284 15.6897 10.9719C15.9121 11.1933 15.933 11.5405 15.752 11.7856L15.6916 11.8558L10.6716 16.8975C10.4495 17.1205 10.1011 17.1408 9.85604 16.9583L9.78585 16.8975L4.76501 11.8558C4.52144 11.6112 4.52226 11.2155 4.76684 10.9719C4.98919 10.7505 5.33645 10.7311 5.58075 10.9131L5.65073 10.9738L10.229 15.5705L14.8058 10.9738Z" fill="white" />
        </svg>
    )
}

export default DownArrow