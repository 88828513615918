const images = {
    blueTick: require('../assets/icon/won-icon.png'),
    trophyIcon: require('../assets/icon/trophy-icon.png'),
    firstIcon: require('../assets/icon/first-icon.png'),
    cardStack: require('../assets/icon/card-stack.png'),
    pointsIcon: require('../assets/icon/points-icon.png'),
    shirtsIcon: require('../assets/icon/shirt-icon.png'),
    infoIcon: require('../assets/icon/info-icon.png'),
    greenTick: require('../assets/icon/green_tick.png'),
    xpPoints: require('../assets/icon/xp_points.png'),
    lock: require('../assets/icon/lock.png'),

    green: require('../assets/icon/green.png'),

    back: require('../assets/icon/back.png'),
    close: require('../assets/icon/close.png'),

    tick: require('../assets/icon/tick.png'),


    playerImage: require('../assets/img/playerImage.png'),
    emptyFrame: require('../assets/img/empty_frame.png'),
    union: require('../assets/img/Unionslider.png'),
    captain_badge: require('../assets/img/captain_badge.png'),
    leaderBoardHeaderBg: require('../assets/img/leaderboard-header-bg.png'),
};

export default images;