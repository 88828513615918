import React from 'react'
import { useNavigate } from "react-router-dom";
import image from "../helper/images";
import CountdownTimer from "./CountdownTimer";




function MyMatchesCard({ item: item }) {
    const navigate = useNavigate();
    const handleClick = () => navigate(`/contest_match_screen/${item.match_id}`, { replace: false });


    function renderStatus(status, timer, statusId) {
        if (statusId === 3) {
            return <div className=' w-max rounded-full backdrop-blur-[72px] flex justify-center items-center px-2 py-0 mt-1'
                style={{ background: 'red' }}
            >
                <div className='mbg-red w-2 h-2 mr-1 rounded-[8px] '
                    style={{ background: 'white' }}
                ></div>
                <span className='text-white text-sm font-semibold'>Live</span>
            </div>
        } else if (statusId === 2) {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>Completed</span>

        } else if (statusId === 1) {
            return <div className='glass-bg rounded-[10px] px-5 flex flex-col justify-center items-center py-1.5 mt-3'>
                <h6 className='font-sans text-xs text-white font-normal '>STARTS IN</h6>
                <h5 className='text-sm text-white font-bold'><CountdownTimer targetDate={timer} /> </h5>
            </div>
        } else if (status === 4) {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>{status}</span>
        }
        else {
            <div></div>
        }
    }


    return (
        <div onClick={handleClick} className='flex flex-col justify-between grey_bg md:min-w-[332px] sm:min-w-[200px] w-full backdrop-blur-[72px] my-3 mr-[20px] rounded-[10px]'>

            <div className=' px-[25px] py-3 flex justify-between items-center'>
                <div className='flex flex-col justify-center'>
                    <img className='w-[46px] h-[46px] rounded-[36px]' src={item.t1_logo} alt="" />
                    <h4 className='text-white text-center text-base font-bold'>{item.t1_short_name}</h4>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <h5 className='text-white text-center text-base font-bold'>{item.league_short_name}</h5>



                    {renderStatus(item.status_str, item.start_time, item.status_id)}


                </div>
                <div className='flex flex-col justify-center'>
                    <img className='w-[46px] h-[46px] rounded-[36px]' src={item.t2_logo} alt="" />
                    <h4 className='text-white text-center text-base font-bold pt-[5px] pb-3'>{item.t2_short_name}</h4>

                </div>
            </div>

            <div className='footer-bg backdrop-blur-[80px] rounded-br-[10px] rounded-bl-[10px]'>
                <div className='flex justify-between items-center '>
                    {item.t1_score != null || item.t1_over != null ? <div className='px-[25px] py-3 flex items-center'>
                        <h4 className='text-white text-xl font-bold text-center'>{item.t1_score}</h4>
                        <span className='font-sans text-white text-xs font-normal text-cente pl-1'> ({item.t1_over})</span>
                    </div> : <div className='px-[25px] py-3 flex items-center'>
                        {/* <h4 className='text-white text-xl font-bold text-center'>{item.t1_score}</h4> */}
                        <span className='font-sans text-white text-xs font-normal text-center pl-1  h-[27px]'> yet to Start</span>
                    </div>}

                    {
                        (item.status_id === 2 && item.won_amount > 0) && <div className='footer-bg'
                            style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)                        ', padding: '5px', paddingLeft: '14px', paddingRight: '14px' }}>
                            <h6 className='font-sans text-xs text-[rgba(255,255,255,0.60)] font-normal '>Won</h6>
                            <div className='flex justify-center' style={{ alignItems: 'center' }}>
                                <img className='w-[15px] h-[15px] pr-0.5' src={image.blueTick} alt="" />
                                <h5 className='font-sans text-base text-white  font-bold' style={{ fontSize: 14 }}>{item.won_amount} </h5>
                            </div>
                        </div>
                    }

                    {item.t2_score != null || item.t2_over != null ? <div className='px-[25px] py-3 flex items-center'>
                        <span className='font-sans text-white text-xs font-normal text-center pr-1'> ({item.t2_over})</span>
                        <h4 className='text-white text-xl font-bold text-center'>{item.t2_score}</h4>
                    </div> : <div className='px-[25px] py-3 flex items-center'>
                        <span className='font-sans text-white text-xs font-normal text-center pr-1  h-[27px]'> yet to Start</span>
                        {/* <h4 className='text-white text-xl font-bold text-center'>{item.t2_score}</h4> */}
                    </div>}
                </div>

            </div>
        </div>

    )
}

export default MyMatchesCard