
import React from 'react';
import images from '../../helper/images'
import Popup from 'reactjs-popup';




const SucessPOPUP = ({ content, onClose }) => {

    return (
        <Popup
            open={true}
            modal
            nested
        >
            <div>
                <div
                    style={{
                        width: '258px',
                        justifyContent: 'center',
                        position: "relative",
                        height: '258px',
                        borderRadius: '20px',
                        opacity: '0.699999988079071',
                        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)',
                        backdropfilter: 'blur(22px)'
                    }}
                >
                    {<button className='close_btn' onClick={onClose} style={{
                        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)',
                        color: 'white',
                        font: 'bold 16px inter',
                        top: '16px',
                        right: '10px',
                        height: '25px',
                        width: '25px',
                        borderRadius: '100px',
                        boxshadow: 'rgba(0, 0, 0, 0.41)',
                        position: 'absolute',
                    }}>
                        X
                    </button>}
                    <div className="col " style={{
                        display: 'grid', placeItems: 'center',
                    }}>
                        <div style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100px',
                            width: '100px',
                            borderRadius: '50px',
                            textAlign: 'center',
                            background: 'rgba(136, 244, 109, 1)',
                        }}>
                            <img className='w-[40px] h-[40px] ' src={images.tick} alt="" />
                        </div>
                        <span className='w-[258px]' style={{
                            display: 'flex',
                            fontfamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '24px',
                            letterSpacing: '0.20000000298023224px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',

                        }}>
                            Transaction Success !
                        </span>

                        {/* <span className='w-260px' style={{
                                placeItems: 'center',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '18px',




                            }}>
                                Your payment could not be processed. Please try again.

                            </span> */}
                        <span style={{
                            placeItems: 'center',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '18px',
                            width: '258px',
                            textAlign: 'center',
                            color: '#F4F4F454'
                        }}>

                            {content}
                        </span>
                    </div>
                </div>
            </div>

        </Popup >
    );
};



export default SucessPOPUP;