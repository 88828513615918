const AppString = {
    MATCHNOTFOUND: "Matches not available for right now. Please wait for an while!!",
    CONTESTNOTFOUND: "No Contest Found",
    LEADERBOARDNOTFOUND: "No DATA Found",

    WINNINGSNOTFOUND: "No DATA Found",
    STATSNOTFOUND: "No DATA Found",
    SCORECARDNOTFOUND: "No DATA Found",

};

export default AppString;
