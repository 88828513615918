import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import JoinMatchesCard from '../components/joinMatchesContest';

import images from "../helper/images";
import TeamList from '../components/team_list/team_list';
import PointsSystemModal from '../components/modal/pointSystemModal';
import { useParams } from 'react-router-dom';
import {
    useQuery,
} from 'react-query';
import Endpoints from '../Endpoints';
import ApiService from '../ApiService';
import CountdownTimer from '../components/CountdownTimer';
import { cacheTime, staleTime } from '../helper/common';
import CustomizeLoader from '../components/custom_loading/custom_loader';

import teamBg from "../assets/img/result_header_bg.png"


const TeamHead = {
    backgroundImage: 'url(' + teamBg + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '100%',
    height: "100%"
}

const JoinMatchScreen = () => {

    const history = useNavigate();
    const handleGoBack = () => {
        history(-1)
    }
    const { id: matchId } = useParams();

    const { isLoading, isError, data: matchDetails, error } = useQuery(`getcontests-${matchId}`, async () => {

        try {
            console.log("API", Endpoints.GETCONTESTS + `?match_id=${matchId}`);
            const response = await ApiService.get(Endpoints.GETCONTESTS + `?match_id=${matchId}`);
            console.log("join contest res", response)
            if (response.status) {
                return response.data;
            }
            return null
        } catch (error) {
            return null
        }
    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })


    const [isOpen, setIsOpen] = useState(false)
    function renderStatus(status = "upcomming", timer) {
        if (status == "live") {
            return <div className='grey_bg w-max rounded-full backdrop-blur-[72px] flex justify-center items-center px-1.5 py-1 mt-3'>
                <div className='mbg-red w-2.5 h-2.5 mr-1 rounded-[10px]'></div>
                <span className='text-white text-sm font-semibold'>Live</span>
            </div>
        } else if (status == "completed") {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>Completed</span>

        } else if (status == "upcomming") {
            return <div className='glass-bg rounded-[10px] px-5 flex flex-col justify-center items-center py-1.5 mt-3'>
                <h6 className='font-sans text-xs text-white font-normal '>STARTS IN</h6>
                <h5 className='text-base text-white font-bold'><CountdownTimer targetDate={timer}></CountdownTimer> </h5>
            </div>
        } else {
            <div></div>
        }
    }
    return (

        <div>
            {isOpen && <PointsSystemModal isOpen={isOpen} setIsOpen={setIsOpen}></PointsSystemModal>}
            <div style={{ height: '50px', display: 'flex', justifyContent: 'space-between', paddingLeft: "8px", paddingRight: "8px" }} className='header-bg flex justify-center items-center py-8'>
                <img onClick={handleGoBack} className='w-[30px] h-[30px] ' src={images.back} alt="" />
                <h2 style={{ fontSize: '24px' }} className='text-white text-2xl font-bold'>
                    Join Contests</h2>
                <div style={{ width: '66px', display: 'flex', justifyContent: 'space-between' }} className=' flex justify-center items-center py-8'>
                    <button onClick={() => {
                        setIsOpen(true)
                    }} >
                        <img className='w-[28px] h-[28px] ' src={images.pointsIcon} alt="" />
                    </button>

                    <TeamList matchID={matchId}>
                    </TeamList>
                </div>
            </div>
            {isLoading ? <CustomizeLoader></CustomizeLoader> : <div>
                <div className='flex flex-col justify-center '>
                    <div className=' w-full h-[90px] relative'>
                        <div style={TeamHead} className=' absolute h-[90px] w-full'></div>

                        <div className=' px-[25px] py-3 flex w-full justify-between items-center bg-transparent absolute'>
                            <div className='flex items-center justify-center'>
                                <img className='w-[46px] h-[46px] rounded-[36px]' src={matchDetails.match.t1_logo} alt="" />
                                <h4 className='text-white text-center text-base font-bold ml-2'>{matchDetails.match.t1_short_name}</h4>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                {renderStatus(matchDetails.match.matchStatus, matchDetails.match.start_time)}
                            </div>
                            <div className='flex items-center justify-center'>
                                <h4 className='text-white text-center text-base font-bold pt-[5px]  mr-2'>{matchDetails.match.t2_short_name}</h4>
                                <img className='w-[46px] h-[46px] rounded-[36px]' src={matchDetails.match.t2_logo} alt="" />


                            </div>
                        </div>
                    </div>
                    <div className='mt-10'>
                        {
                            matchDetails &&
                            matchDetails.contests &&
                            matchDetails.contests.length > 0 &&
                            matchDetails.contests.map((e, i) =>
                                <JoinMatchesCard key={`JoinMatchesCard${i}`} item={e} match_id={matchId}>
                                </JoinMatchesCard>)
                        }
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default JoinMatchScreen;