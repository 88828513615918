import * as React from 'react';
import styled from 'styled-components';


const Button = styled.button`
  background-color: white;
  color: black;
  font-size: 20px;
  height: 45px;
  width: 50%;
  padding: 10px 60px;
  border-radius: 30px;
  margin: 10px 0px;
  cursor: pointer;
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
  
`;


const PrimaryButton = ({ onClick, children, disabled }) => {
  return (
    <Button type="button" onClick={onClick} size="lg" disabled={disabled} >
      {children}
    </Button>
  );
};


export default PrimaryButton;