import React, { useEffect, useMemo, useState } from 'react'
import PointModal from '../pointModal';
import images from '../../helper/images';
import SwipeableButton from '../swipeButton';
import BackgroundHeader from "../../assets/img/bg.png"
import {
    useQuery,
} from 'react-query'
import Endpoints from '../../Endpoints';
import ApiService from '../../ApiService';
import { cacheTime, staleTime } from '../../helper/common';
const BackgroundHead = {
    backgroundImage: 'url(' + BackgroundHeader + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

function PointsSystemModal({ isOpen, setIsOpen }) {
    let tabs = ["Base points", "Xp boost", "Tie breaker",];
    let baseTabs = ["T20", "ODI", "Test",];

    const [activeTab, setActiveTab] = useState(0);
    const [activeSubTab, setActiveSubTab] = useState(0);
    const [mainTabs, setMainTabs] = useState([]);

    const [subTabs, setSubTabs] = useState([]);
    const [baseTabactiveTab, setBaseTabActiveTab] = useState(0);

    const { isLoading, isError, data: pointSystem, error } = useQuery('point-systems', async () => {

        try {
            const response = await ApiService.get(Endpoints.POINTSYSTEM);
            if (response.status) {
                console.log("return response.data");
                return response.data;
            }
            return null
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return null
        }

    }, {
        staleTime: staleTime, // 10 mins 
        cacheTime: cacheTime, //15 mins
    })

    const selectedFormat = useMemo(() => {
        let selectedSubTabs;
        if (activeSubTab && pointSystem.pointsSystem) {
            let index = pointSystem.pointsSystem.findIndex((e) => e.matchFormat == activeSubTab);
            selectedSubTabs = index == -1 ? null : pointSystem.pointsSystem[index].data
        }
        return selectedSubTabs;
    }, [activeSubTab])


    // console.log("selectedFormat", selectedFormat);

    useEffect(() => {
        if (pointSystem) {
            let formatTabs = [];
            // get the keys from object and split the camelcase letter into two pieces
            let mainTabs = Object.keys(pointSystem).map((e) => {
                return {
                    key: e,
                    name: e.replace(/([a-z])([A-Z])/g, '$1 $2')
                }
            })
            console.log("mainTabs", mainTabs);
            formatTabs = pointSystem.pointsSystem.map((e) => e.matchFormat);
            if (mainTabs.length > 0) {
                setMainTabs(mainTabs);
                setActiveTab(mainTabs[0])
            }
            if (formatTabs.length > 0) {
                setSubTabs(formatTabs)
                setActiveSubTab(formatTabs[0])
            }


        }
    }, [pointSystem])


    const handleTabClick = (item) => {
        console.log("on index click", item);
        setActiveTab(item);
    };


    function renderMainTab() {

        if (activeTab.key == "pointsSystem") {
            return <div>
                <div className='flex items-center  justify-center'>
                    <div className='flex items-center  justify-center mb-3 w-max rounded-full  bg-white/10'>
                        {
                            subTabs.map((e) => {
                                return <button onClick={() => {
                                    console.log("onclikc subtabs", e);
                                    setActiveSubTab(e)
                                }} key={"sub tab" + e} className={`rounded-full  text-white text-base leading-[0.8rem] font-bold px-4 py-2  ${activeSubTab == e ? "bg-white/20 pointsTab_bg" : "bg-transparent"}`}>{e}</button>

                            })
                        }

                    </div>
                </div>

                <div className='w-full mt-2'>
                    <div class="flex flex-col">
                        {
                            selectedFormat && selectedFormat.map((e) => {
                                return <details class="group">
                                    <summary class="flex justify-between items-center font-medium cursor-pointer list-none px-5 bg-white/10 py-3 mb-1">
                                        <div className='flex flex-col'>
                                            <span className='text-white text-base font-semi-bold'> {e.event_category}</span>
                                            <span className='font-sans text-white/50 text-sm font-normal'>{e.sub_string}</span>
                                        </div>
                                        <span class="transition group-open:rotate-180">
                                            <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                                            </svg>
                                        </span>
                                    </summary>
                                    <p class="text-neutral-600 mt-3 mb-1 group-open:animate-fadeIn px-5">
                                        <div className='flex flex-col'>
                                            {
                                                e.pointsList && e.pointsList.map((el) =>
                                                    <div className='flex justify-between items-center'>
                                                        <h6 className='font-sans text-base font-normal text-white'>{el.event_string}</h6>
                                                        <span className={`w-[50px] h-[21px] text-center font-sans rounded-xl my-1 text-base font-bold text-white ${el.event_points > 0 ? 'bg-[#00AC4F]' : 'bg-[#EE3131]'}`}>{el.event_points}</span>
                                                    </div>)
                                            }

                                            {/* <div className='flex justify-between items-center'>
                                                <h6 className='font-sans text-base font-nomral text-white'>LBW/ Bowled Bonus</h6>
                                                <span className='font-sans bg-[#00AC4F] rounded-full px-2 py-1 my-1 text-base font-bold text-white'> +120</span>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <h6 className='font-sans text-base font-nomral text-white'>4 Wkt Bonus</h6>
                                                <span className='font-sans rounded-full px-2 py-1 my-1 text-base font-bold text-white'> +123</span>
                                            </div> */}
                                        </div>
                                    </p>
                                </details>
                            })
                        }

                    </div>

                </div>
            </div>
        } else if (activeTab.key == "xpBoost") {
            return <div>
                <h6 className='text-[#9C9C9C] text-sm  font-normal px-6 text-center'>
                    {pointSystem['xpBoost'].heading}
                </h6>
                <div className='mt-8 mx-6 bg-white/10 rounded-xl p-6'>
                    <ol className='list-decimal px-4'>
                        {pointSystem['xpBoost'].points.map(e =>
                            <li className='mb-5'>{e}</li>)}
                    </ol>

                </div>
            </div>
        } else if ((activeTab.key == "tieBreaker")) {
            return <div>
                <h6 className='text-[#9C9C9C] text-sm  font-normal px-6 text-center'>
                    {pointSystem['tieBreaker'].heading}
                </h6>
            </div>
        }

    }


    return (
        <PointModal isOpen={isOpen} onClose={() => {
            setIsOpen(false)
        }}>
            <div className='relative h-full max-h-full w-full rounded-b-xl overflow-y-auto pointsSystem' style={BackgroundHead}>
                <div className='flex items-center justify-center py-4'>
                    {
                        mainTabs.map((e, i) => {
                            return <button onClick={() => {
                                handleTabClick(e)
                            }} key={"tabsss" + i} className={`rounded-full text-white text-sm leading-[0.8rem] capitalize font-bold px-3 py-2 mx-1 ${activeTab && activeTab.name == e.name ? "bg-[#8100ac]" : "mbg-grey"}`}>{e.name}</button>

                        })
                    }

                </div>

                {activeTab && renderMainTab()}

            </div>

        </PointModal>
    )
}


export default PointsSystemModal