import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import JoinMatchesCard from '../components/joinMatchesContest';
import fakeMatches from "../assets/mockData/my_matches.json";
import images from "../helper/images";
import TeamList from '../components/team_list/team_list';
import TeamSelectionTab from '../components/teamSelectionTab';
import CancelButton from '../components/icon_component/cancel';
import CardBuyModal from '../components/modal/cardBuyModal';
import Endpoints from "../Endpoints";
import { useQuery } from 'react-query';
import ApiService from "../ApiService";
import CountdownTimer from "../components/CountdownTimer";
import { useParams } from 'react-router-dom';
import Card from "../components/carousel/Card";
import SelectCaptain from "./select_captain";
import CustomizeLoader from "../components/custom_loading/custom_loader";
import { cacheTime, staleTime } from '../helper/common';

const SelectTeamScreen = () => {
    const { matchId, contestId } = useParams();

    const [isOpen, setIsOpen] = useState(false);
    const [playerName, setPlayerName] = useState();
    const [playerId, setPlayerId] = useState();
    const [players, setPlayers] = useState();
    const [captain, setCaptain] = useState(false);
    const [finalTeam, setFinalTeam] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isError, setisError] = useState(false);

    useEffect(() => {
        console.log("called effect")
        refetch();
    }, []);

    const { isLoading, isError: apiError, data: team, error, refetch } = useQuery(`select-team-${contestId}`, async () => {
        try {
            const response = await ApiService.get(Endpoints.TEAM(matchId, contestId));
            console.log("players list", response)
            if (response.status) {
                let selected_players = response.data.joinedPlayerCards;
                let updatedMinimumReq = minimumReq.map(e => {
                    selected_players.forEach(player => {
                        if (
                            (
                                (e.type.toLowerCase() === player.role.toLowerCase() || player.role.toLowerCase() === "ar")
                                && e.card === null
                            ) ||
                            (e.type.toLowerCase() === "any" && e.card === null)
                        ) {
                            if (checkPlayerExist(player, player) === -1) {
                                e.card = player;
                            }
                        }
                    });
                    return e;
                });
                console.log("updatedMinimumReq", updatedMinimumReq)
                setMinimumReq(updatedMinimumReq);

                return response.data;
            } else {
                setErrorMessage(response.message);
                setisError(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setErrorMessage(error.message);
            setisError(true);
        }

    }, {
        staleTime: staleTime,
        cacheTime: cacheTime
    })



    let [minimumReq, setMinimumReq] = useState([
        {
            "text": "BAT",
            "type": "BATSMEN",
            card: null
        },
        {
            "text": "BOWL",
            "type": "BOWLER",
            card: null
        },
        {
            "text": "ANY",
            "type": "ANY",
            card: null
        }
    ])
    const history = useNavigate();
    const handleGoBack = () => {
        history(-1)
    }

    const proceed = () => {
        history("/select_captain", { replace: false })
    }

    function onSelectPlayer(selectedCard) {
        if (!selectedCard.owned) {
            setPlayerName(selectedCard.name);
            setPlayerId(selectedCard.player_id);
            setIsOpen(true);
        } else {
            let data = minimumReq.map(e => {
                if ((((e.type.toLowerCase() == selectedCard.role.toLowerCase() || selectedCard.role.toLowerCase() == "ar") && e.card == null) ||
                    (e.type.toLowerCase() == "any" && e.card == null)) && checkPlayerExist(selectedCard) === -1) {
                    e.card = selectedCard;
                }
                return e;
            });
            setMinimumReq(data);
        }
    }
    function checkPlayerExist(selectedCard) {
        const playerIndex = minimumReq.findIndex((player) => player.card != null && player.card.player_id === selectedCard.player_id);
        return playerIndex;
    }

    function getRuleIndex(rule) {
        const ruleIndex = minimumReq.findIndex((player) => player.type == rule);
        return ruleIndex;
    }

    function onUnSelectPlayer(type) {
        let data = minimumReq.map(e => {
            if (e.type.toLowerCase() == type.toLowerCase()) {
                e.card = null;
            }
            return e;
        })

        setMinimumReq(data)
    }

    function submitTeam() {
        const selectedPlayers = minimumReq.map((element) => ({
            key: element.card.player_id,
            content: (
                <Card imagen={element.card.image_url} />
            )
        }));

        const finalTeamTemp = []
        for (const element of minimumReq) {
            finalTeamTemp.push({
                playerId: element.card.player_id,
                cardId: element.card.bowled_user_card_id,
                xp: element.card.xp || 0
            });
        }

        console.log("final selected players", selectedPlayers);
        setPlayers(selectedPlayers);
        setFinalTeam(finalTeamTemp);
        setCaptain(true);
        // proceed();
    }

    function renderStatus(status, endTime) {
        if (status == "live") {
            return <div className='grey_bg w-max rounded-full backdrop-blur-[72px] flex justify-center items-center px-1.5 py-1 mt-3'>
                <div className='mbg-red w-2.5 h-2.5 mr-1 rounded-[10px]'></div>
                <span className='text-white text-sm font-semibold'>Live</span>
            </div>
        } else if (status == "completed") {
            return <span className='rounded-full px-1.5 py-1  mt-3 mbg-green text-xs text-white font-semibold'>Completed</span>

        } else if (status == "upcoming" || status == "Scheduled") {
            return <div className='glass-bg rounded-[10px] px-5 flex flex-col justify-center items-center py-1.5 mt-3'>
                <h6 className='font-sans text-xs text-white font-normal '>STARTS IN</h6>
                <h5 className='text-base text-white font-bold'><CountdownTimer targetDate={endTime} /></h5>
            </div>
        } else {
            <div></div>
        }
    }
    return (
        <div>
            {captain && <SelectCaptain cards={players} team={team} contestId={contestId} finalTeam={finalTeam} />}

            {!captain &&
                <div className='relative'>
                    {
                        isOpen &&
                        <CardBuyModal playerName={playerName} playerId={playerId} isOpen={isOpen} setIsOpen={setIsOpen}></CardBuyModal>
                    }
                    <div style={{ height: '50px', display: 'flex', justifyContent: 'space-between', paddingLeft: "8px", paddingRight: "8px" }}
                        className='header-bg flex justify-center items-center py-8'>
                        <img onClick={handleGoBack} className='w-[30px] h-[30px] ' src={images.back} alt="" />
                        <h2 style={{ fontSize: '24px' }} className='text-white text-2xl font-bold'>
                            Select Team
                        </h2>
                        <div style={{ width: '45px', display: 'flex', justifyContent: 'space-between' }} className=' flex justify-center items-center py-8'>
                            {/* <img className='w-[40px] h-[40px] ' src={images.pointsIcon} alt="" /> */}

                            {/* todo please added match id on teamList */}
                            <TeamList matchID={matchId}>
                            </TeamList>
                        </div>
                    </div>
                    {errorMessage && (
                        <div className="w-1/2 m-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">{errorMessage}</strong>
                            {/* <span className="block sm:inline">Something seriously bad happened.</span> */}
                            <span onClick={() => setErrorMessage(null)} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                            </span>
                        </div>
                    )}
                    {isLoading ? <CustomizeLoader></CustomizeLoader> :
                        (!isLoading && !captain && !isError && !errorMessage) && <div>
                            <div className=' w-full h-[90px] relative'>
                                <div className="bg-[url('./assets/img/select_team_match_bg.png')] absolute h-[90px] w-full"></div>

                                <div className=' px-[25px] py-3 flex w-full justify-between items-center bg-transparent absolute'>
                                    <div className='flex items-center justify-center'>
                                        <img className='w-[46px] h-[46px] rounded-[36px]' src={team.match.t1_logo} alt="" />
                                        <h4 className='text-white text-center text-base font-bold ml-2'>{team.match.t1_short_name}</h4>
                                    </div>
                                    <div className='flex flex-col justify-center items-center'>
                                        {renderStatus(team.match.status_str, team.match.start_time)}
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <h4 className='text-white text-center text-base font-bold pt-[5px]  mr-2'>{team.match.t2_short_name}</h4>
                                        <img className='w-[46px] h-[46px] rounded-[36px]' src={team.match.t2_logo} alt="" />
                                    </div>
                                </div>
                            </div>
                            {team.playerCards.length > 0 &&
                                <TeamSelectionTab players={team.playerCards} tabs={team.roles} onSelectPlayer={onSelectPlayer}>
                                </TeamSelectionTab>
                            }

                            <div className='relative h-[60px]'>
                                <div className='absolute top-0 left-0 h-12 w-full glassmorphism-bg opacity-30 border-y-white border-t border-solid border-b'></div>
                                <div className='absolute top-0 left-0 px-[1.375rem] py-3 flex items-center'>
                                    <h3 className='text-white text-base font-bold uppercase min-[319px]:text-[10px] min-[375px]:text-[13px] min-[425px]:text-[16px]'>MINIMUM :</h3>
                                    {
                                        team.rules.map((e, i) => <div key={'rules' + i} className='flex items-center ml-2'>
                                            <div className={`w-[15px] h-[15px] mr-1 border-[1.821px] border-solid border-[#8D8D8D] rounded-xl flex items-center justify-center p-0.5`}>
                                                {
                                                    minimumReq.some((obj) => obj.card && (obj.card.ruleKeys.includes(e.ruleKeys[0]) || obj.card.ruleKeys.includes("AR"))) && <img src={images.greenTick} className='w-full h-full' alt="" />
                                                }
                                            </div>
                                            <h4 className='min-[319px]:text-[10px] min-[375px]:text-[13px] min-[425px]:text-[16px] text-white text-base font-bold uppercase'> {e.roleName.toUpperCase()}</h4>
                                        </div>)
                                    }

                                </div>
                            </div>

                            <div className='flex items-center justify-center relative mt-5 pb-4'>
                                {
                                    minimumReq.map((e, i) => <div key={'min' + i} className='w-[90px] h-[136px] relative flex items-center mx-4'>
                                        <img src={e.card ? e.card.image_url : images.emptyFrame} className='absolute w-full h-full' alt="" />
                                        {
                                            e.card && <div className='absolute right-[-12px] top-[-12px]'
                                                onClick={
                                                    () => {
                                                        onUnSelectPlayer(e.type)
                                                    }
                                                }>
                                                <CancelButton></CancelButton>
                                            </div>
                                        }
                                        <h4 className='opacity-[60%] w-full text-center text-white text-base font-bold uppercase'> {!e.card ? e.type.toUpperCase() : ""}</h4>
                                    </div>)
                                }
                            </div>
                            <div onClick={submitTeam} className='flex justify-center'><button disabled={minimumReq.some(e => e.card == null)} className={`rounded-full w-[50%] text-black text-base font-bold px-4 py-2 mt-4 mb-6 bg-white  disabled:opacity-75 `}>Proceed</button> </div>
                        </div>}
                </div>}

        </div>
    );
};

export default SelectTeamScreen;