import React from 'react'

function CancelButton() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
            <g filter="url(#filter0_d_324_15081)">
                <g filter="url(#filter1_b_324_15081)">
                    <circle cx="19.5" cy="12.5" r="12.5" fill="url(#paint0_linear_324_15081)" fillOpacity="0.5" />
                </g>
                <path d="M15 8L19.5 12.5M19.5 12.5L24 17M19.5 12.5L15 17M19.5 12.5L24 8" stroke="white" strokeWidth="2" />
            </g>
            <defs>
                <filter id="filter0_d_324_15081" x="0" y="0" width="33" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-3" dy="5" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.41 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_324_15081" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_324_15081" result="shape" />
                </filter>
                <filter id="filter1_b_324_15081" x="-41.8029" y="-48.8029" width="122.606" height="122.606" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="24.4014" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_324_15081" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_324_15081" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_324_15081" x1="19.5" y1="-8.72093" x2="19.5" y2="39.0504" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6464" />
                    <stop offset="1" stopColor="#FF3F3F" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default CancelButton