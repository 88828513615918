const apiUrl = "https://api.fantasy.techwhizz.dev";
// const apiUrl = "http://localhost:3000";

const ApiService = {

    AuthToken: () => `Bearer ${localStorage.getItem('jwtToken')}`,

    get: async (endpoint, headers) => {
        const response = await fetch(`${apiUrl}/${endpoint}`, {
            method: "GET",
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': ApiService.AuthToken(),
                ...headers
            }
        });
        return response.json();
    },

    post: async (endpoint, data) => {
        const response = await fetch(`${apiUrl}/${endpoint}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                'Authorization': ApiService.AuthToken()
            },
            body: JSON.stringify(data),
        });
        // if (!response.ok) {
        //     throw new Error(`POST request failed with status: ${response.status}`);
        // }
        return response.json();
    },

    put: async (endpoint, data) => {
        const response = await fetch(`${apiUrl}/${endpoint}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error(`POST request failed with status: ${response.status}`);
        }
        return response.json();
    },

    delete: async (endpoint) => {
        const response = await fetch(`${apiUrl}/${endpoint}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error(`POST request failed with status: ${response.status}`);
        }
        return response.json();
    },
};

export default ApiService;
