import React, { Component } from 'react';
import './swipebutton.css';

export default class SwipeableButton extends Component {
    sliderRef = React.createRef();
    containerRef = React.createRef();
    isTouchDevice = 'ontouchstart' in document.documentElement;

    state = {
        unlocked: false,
        showSuccess: false,
        showFailure: false,
        isLoading: false,
    };

    containerWidth = 0;
    startX = 0;
    isDragging = false;

    componentDidMount() {
        if (this.isTouchDevice) {
            document.addEventListener('touchmove', this.onDrag);
            document.addEventListener('touchend', this.stopDrag);
        } else {
            document.addEventListener('mousemove', this.onDrag);
            document.addEventListener('mouseup', this.stopDrag);
        }
        this.containerWidth = this.containerRef.current.clientWidth - 60;
    }

    onDrag = (e) => {
        if (this.isDragging && !this.state.unlocked) {
            const clientX = this.isTouchDevice ? e.touches[0].clientX : e.clientX;
            const newOffsetX = clientX - this.startX;
            this.sliderRef.current.style.transform = `translateX(${newOffsetX}px)`;
        }
    };

    stopDrag = () => {
        if (this.isDragging && !this.state.unlocked) {
            this.isDragging = false;
            const sliderPosition = parseInt(this.sliderRef.current.style.transform.slice(11));
            if (sliderPosition > this.containerWidth * 0.9) {
                this.unlock();
            } else {
                this.resetSlider();
            }
        }
    };

    startDrag = (e) => {
        if (!this.isDragging && !this.state.unlocked) {
            this.isDragging = true;
            this.startX = this.isTouchDevice ? e.touches[0].clientX : e.clientX;
        }
    };

    unlock = () => {
        this.setState({ unlocked: true, showSuccess: true, isLoading: true });

        // Perform success action
        if (this.props.onSuccess) {
            this.props.onSuccess();
        }

        // Reset to starting state after 2 seconds
        setTimeout(() => {
            this.setState(
                { unlocked: false, showSuccess: false, isLoading: false },
                this.resetSlider
            );
        }, 2000);
    };

    resetSlider = () => {
        this.sliderRef.current.style.transform = 'translateX(0)';
    };

    componentWillUnmount() {
        if (this.isTouchDevice) {
            document.removeEventListener('touchmove', this.onDrag);
            document.removeEventListener('touchend', this.stopDrag);
        } else {
            document.removeEventListener('mousemove', this.onDrag);
            document.removeEventListener('mouseup', this.stopDrag);
        }
    }

    render() {
        const { unlocked, showSuccess, isLoading } = this.state;
        const buttonText = unlocked ? (this.props.text_unlocked || 'UNLOCKED') : (this.props.text || 'SWIPE');
        const successText = this.props.successText || 'SUCCESS';
        const loadingText = this.props.loadingText || 'LOADING';

        return (
            <div className="w-full">
                <div
                    className={`rsbContainer ${unlocked ? 'rsbContainerUnlocked' : ''} w-4/5 absolute bottom-0`}
                    ref={this.containerRef}
                >
                    <div className='rsbcText flex items-center justify-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="21" viewBox="0 0 50 21" fill="none">
                            <path d="M6.09424 10.0437L0 0L8.54152 0.0167261L14.9095 10.061L7.88348 21L0.10851 20.9848L6.09424 10.0437Z" fill="white" fillOpacity="0.2" />
                            <path d="M17.7911 10.0437L11.6968 0L20.2383 0.0167261L26.6063 10.061L19.5803 21L11.8053 20.9848L17.7911 10.0437Z" fill="white" fillOpacity="0.2" />
                            <path d="M29.4879 10.0437L23.3937 0L31.9352 0.0167261L38.3032 10.061L31.2771 21L23.5022 20.9848L29.4879 10.0437Z" fill="white" fillOpacity="0.2" />
                            <path d="M41.1847 10.0437L35.0905 0L43.632 0.0167261L50 10.061L42.974 21L35.199 20.9848L41.1847 10.0437Z" fill="white" fillOpacity="0.2" />

                        </svg>
                        <h5 className=' text-white text-base font-normal mx-1'> Swipe To Buy</h5>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="21" viewBox="0 0 50 21" fill="none">
                            <path d="M6.09424 10.0437L0 0L8.54152 0.0167261L14.9095 10.061L7.88348 21L0.10851 20.9848L6.09424 10.0437Z" fill="white" fillOpacity="0.2" />
                            <path d="M17.7911 10.0437L11.6968 0L20.2383 0.0167261L26.6063 10.061L19.5803 21L11.8053 20.9848L17.7911 10.0437Z" fill="white" fillOpacity="0.2" />
                            <path d="M29.4879 10.0437L23.3937 0L31.9352 0.0167261L38.3032 10.061L31.2771 21L23.5022 20.9848L29.4879 10.0437Z" fill="white" fillOpacity="0.2" />
                            <path d="M41.1847 10.0437L35.0905 0L43.632 0.0167261L50 10.061L42.974 21L35.199 20.9848L41.1847 10.0437Z" fill="white" fillOpacity="0.2" />

                        </svg>
                    </div>
                    <div
                        className="rsbcSlider"
                        ref={this.sliderRef}
                        onMouseDown={this.startDrag}
                        onTouchStart={this.startDrag}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="19" viewBox="0 0 12 19" fill="none">
                            <path d="M2 2L9 9.5L2 17" stroke="#707070" strokeWidth="3" />
                        </svg>
                        {/* {isLoading ? (
                            <span className="rsbcSliderText">{loadingText}</span>
                        ) : showSuccess ? (
                            <span className="rsbcSliderText">{successText}</span>
                        ) : (
                            <>
                                <span className="rsbcSliderText">{buttonText}</span>
                                <span className="rsbcSliderArrow"></span>
                                <span className="rsbcSliderCircle"></span>
                            </>
                        )} */}

                    </div>
                    {/* <div className="rsbcText text-white">

                        {isLoading ? loadingText : showSuccess ? successText : buttonText}
                    </div> */}
                </div>
            </div>
        );
    }
}
