
import React, { useEffect, useState } from 'react';
import MyMatchesCard from './myMatchesCard';
import UpcommingMatchesCard from './upcommingMatch';
import { useNavigate, useLocation } from "react-router-dom";
import ApiService from "../ApiService";
import Endpoints from "../Endpoints";
import { useCookies } from 'react-cookie';
import {
    useQuery,
} from 'react-query'
import { cacheTime, staleTime } from '../helper/common';
import CustomizeLoader from './custom_loading/custom_loader';
import AppString from '../appstring';


const Home = () => {
    const [isMyMatchLoading, setisMyMatchLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [myMatchData, setmyMatchData] = useState([]);
    const [data, setData] = useState([]);

    const [cookies, setCookie] = useCookies(['name']);
    const navigate = useNavigate();
    const handleMenuClick = () => navigate("/contest_match_screen", { replace: false });
    const UpcomingClick = () => navigate("/up_coming_match_screen", { replace: false });
    // const MyScreenClick = () => navigate("/leaderboard", { replace: false });
    const MyScreenClick = () => navigate("/my_match_screen", { replace: true });

    async function upcomingMatch() {
        try {
            const response = await ApiService.get(Endpoints.GETMATCHES);
            console.log("upcoming matches res", response)
            if (response.data.matches) {
                setData(response.data.matches)
                console.log("data set", data)
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error.message);
            setIsLoading(false);
        }

    }

    async function auth() {
        const xsrfToken = '0d07e1f5-5cfd-41eb-9f7d-00a38914d510';
        const accessToken = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0NiIsImV4cCI6MTY5MjYzOTk5OSwiaWF0IjoxNjkyMjA3OTk5fQ.tryVTYS0igzgMcDyl48U252amQ3xv8kQBZGEhdgM08A';
        const refreshToken = 'f202ccd4-3610-488e-8e4a-84f8608b7b35';

        // Set the cookies using js-cookie fot localhost
        // setCookie('XSRF-TOKEN', xsrfToken);
        // setCookie('access_token', accessToken);
        // setCookie('refresh_token', refreshToken);

        // setCookie('XSRF-TOKEN', xsrfToken, {
        //     secure: true,
        //     sameSite: 'strict',
        //     domain: '.fantasy.techwhizz.dev'
        // });
        // setCookie('access_token', accessToken, {
        //     secure: true,
        //     sameSite: 'strict',
        //     domain: '.fantasy.techwhizz.dev'
        // });
        // setCookie('refresh_token', refreshToken, {
        //     secure: true,
        //     sameSite: 'strict',
        //     domain: '.fantasy.techwhizz.dev'
        // });

        try {
            // const storedToken = localStorage.getItem('jwtToken');
            // if (!storedToken) {
            const searchParams = new URLSearchParams(document.location.search);
            const authKey = searchParams.get('authKey');
            console.log(authKey);
            const auth = await ApiService.get(Endpoints.USER, {
                "refToken": authKey //"f5a0686a-75e7-429b-8ae7-6f67da57807c"
            });
            console.log("Auth res", auth)
            if (auth.status && auth.data.token) {
                localStorage.setItem('jwtToken', auth.data.token);
                upcomingMatch();
                myMatches();
            }
            // } else {
            //     upcomingMatch();
            //     myMatches();
            // }
            return []
        } catch (error) {
            console.error("Error fetching data:", error);
            return []
        }

    }

    async function myMatches() {

        try {
            const res = await ApiService.get(Endpoints.MYMATCHES);
            console.log("My matches res:", res.data);
            if (res.data) {
                setmyMatchData(res.data);
            }
            setisMyMatchLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setisMyMatchLoading(false);
        }

    }

    useEffect(() => {
        console.log("Called")
        auth();
    }, []);

    return (
        <div>
            <div style={{ height: '50px' }} className='header-bg flex justify-center items-center py-8'>
                <h2 style={{ fontSize: 24, }} className='text-white text-2xl font-bold'>Bowled Fantasy</h2>
            </div>
            {/* <DeleteTeamPopup onYesClick={() => { }}></DeleteTeamPopup> */}
            {(!isMyMatchLoading && myMatchData && myMatchData.matches.all.length > 0) && <div>
                <div className='flex justify-between items-center  p-[25px]'>
                    <h3 style={{ fontSize: 24, }} className='text-white text-2xl font-bold'>My Matches</h3>
                    <button onClick={() => {
                        MyScreenClick()
                    }} className='font-sans text-[#D39AFF] text-sm font-normal'>See all</button>
                </div>
                {
                    (!isMyMatchLoading && myMatchData && myMatchData.matches.all.length > 0) &&
                    <div>
                        <div className='flex items-center no-scrollbar overflow-x-scroll hide-scroll-bar'>
                            <div className='flex justify-center px-[25px]  '>
                                {
                                    // myMatchData && myMatchData.all?.map((e, i) => <MyMatchesCard key={"mymatches"+i} item={e} ></MyMatchesCard>)
                                    myMatchData.matches.all.length > 0 && myMatchData.matches.all.map((item, index) => <MyMatchesCard key={index + "Mym23scatches"} item={item} ></MyMatchesCard>)}
                            </div>
                        </div>
                    </div>}
            </div>}


            {/* <TeamSelectionTab></TeamSelectionTab>

            {

                fakeContests.map((e, i) => <JoinMatchesCard key={i + "joinMatches"} item={e} ></JoinMatchesCard>)

            } */}
            <div className=' flex justify-between items-center  p-[25px]'>
                <h2 style={{ fontSize: 24, }} className='text-white text-2xl font-bold'>Upcoming Matches</h2>
                {/* <button onClick={UpcomingClick} className='font-sans text-[#D39AFF] text-sm font-normal'>See all</button> */}

            </div>
            <div className='flex flex-col items-center justify-center px-[25px]'>
                {
                    isLoading ? <CustomizeLoader size={65}></CustomizeLoader> :

                        !isLoading && data.length <= 0 ? <div className='flex flex-col items-center justify-center h-screen'>
                            {AppString.MATCHNOTFOUND}
                        </div> :
                            data && data?.map((e, i) => <UpcommingMatchesCard key={"upcommingmatches" + i} item={e} ></UpcommingMatchesCard>)

                }

            </div>
            {/* <MyMatchesCard>

            </MyMatchesCard> */}
        </div>);
};

export default Home;
