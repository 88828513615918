// import { colors } from "@mui/material";
// import React from "react";

import React from 'react';
import BackgroundHeader from "../../assets/img/bg.png"
import images from '../../helper/images'
import Popup from 'reactjs-popup';
import TeamPlayerList from "../../assets/mockData/team_players_list.json"
import leaderboard from "../../assets/mockData/player_status.json"

import Endpoints from '../../Endpoints';
import ApiService from '../../ApiService';
import {
    useQuery,
} from 'react-query';
import { cacheTime, staleTime } from '../../helper/common';



const PlayerStats = ({ children, playerId, matchId }) => {

    const { isLoading, isError, data: playerStat, error, refetch } = useQuery(`leaderboard-player-stats-${matchId}-${playerId}`, async () => {

        try {
            const response = await ApiService.get(Endpoints.PLAYERSTATS + `?match_id=${matchId}&player_id=${playerId}`);
            console.log("response playerstat", response);
            if (response.status) {
                return response.data.stat || [];
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }
    },
        {
            staleTime: staleTime,
            cacheTime: cacheTime,
            enabled: false,
        }
    )

    // console.log("playerStat", playerStat);


    return (
        <Popup
            trigger={
                children
            }
            modal
            nested
            onOpen={() => {
                refetch()
            }}

        >
            {close => (
                <div className='h-4/5'>
                    {isLoading || !playerStat ? <h6 className='text-center mt-10'>Loading...</h6> : <div style={{
                        width: '340px', height: "500px",

                        borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", background: "#FFFFFF14",
                    }}>

                        <div style={{
                            height: '100Px',
                        }} >
                            <img style={{
                                position: 'absolute',
                                top: '-60px',
                                left: '10px',
                            }} className='w-[90px] h-[130px] ' src={playerStat.playerImage} alt="" />
                            <span style={{
                                color: 'white',
                                justifyContent: 'center',
                                display: 'flex',
                                padding: '10px',
                                paddingLeft: '0px',
                                marginLeft: '90px',
                                fontFamily: 'Inter',
                                fontSize: '24px',
                                fontWeight: 700,
                                lineHeight: '39px',
                                letterSpacing: '0.002em',
                                textAlign: 'center',
                            }}>
                                {playerStat.playerName.length > 10 ? playerStat.playerName.slice(0, 10) + '...' : playerStat.playerName}
                            </span>
                            <div style={{ width: '330px', display: 'flex', justifyContent: 'end', alignItems: "center", alignContent: 'center' }} className='col'>

                                <div style={{ width: '330px', display: 'flex', justifyContent: 'end', alignItems: "center", alignContent: 'center' }} className='col'>


                                    <img className='w-[28px] h-[28px] ' style={{


                                    }} src={images.pointsIcon} alt="" />

                                    <span style={{
                                        color: 'white', justifyContent: 'center', display: 'flex', fontFamily: 'Product Sans',
                                        paddingLeft: '8px',
                                        paddingRight: '16px',
                                        fontSize: '14px',
                                        fontWeight: 400,

                                        textAlign: 'center',
                                    }}>Base Points</span>
                                    <span style={{
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        color: 'white', justifyContent: 'center', display: 'flex', fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontWeight: 700,

                                        textAlign: 'center',
                                    }}> {playerStat.totalPoints}</span>
                                </div>
                            </div>

                            <button className='close_btn' onClick={close} style={{
                                background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)',
                                color: 'white',
                                font: 'bold 16px inter',
                                top: '16px',
                                right: '10px',
                                height: '25px',
                                width: '25px',
                                borderRadius: '100px',
                                boxshadow: 'rgba(0, 0, 0, 0.41)',
                                position: 'absolute',
                            }}>
                                X
                            </button></div>
                        <div className="list-view" style={{
                            overflowY: "scroll",
                            height: "400px",


                        }}>
                            <table style={{

                                display: "felx",

                                justifyContent: 'center',
                                height: "100px",

                            }}>
                                <div style={{
                                    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) -34.88%, rgba(255, 255, 255, 0) 156.2%)', paddingTop: "8px",
                                    paddingBottom: "8px",
                                    width: '330px'
                                }}>
                                    <tr>
                                        <th style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontFamily: 'inter',
                                            fontSize: '16px',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                            paddingLeft: "16px",
                                            width: '170px',
                                            fontFamily: 'Inter',
                                        }}>EVENTS</th>
                                        <th style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontFamily: 'inter',
                                            fontSize: '16px',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            textAlign: 'right',
                                            fontFamily: 'Inter',
                                            width: '75px',
                                        }}>ACTUAL</th>
                                        <th style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontFamily: 'inter',
                                            fontSize: '16px',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            textAlign: 'right',
                                            fontFamily: 'Inter',
                                            width: '75px',
                                        }}>POINTS</th>

                                    </tr>

                                </div>

                                {playerStat.events.map((val, key) => {
                                    return (
                                        <div style={{
                                            paddingLeft: "16px",
                                            paddingRight: "16px",
                                            width: '330px'
                                        }} >
                                            <tr key={key} style={{
                                                paddingLeft: "16px",
                                                paddingRight: "16px",

                                                width: '330px'
                                            }}>
                                                <td style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontFamily: 'inter',
                                                    fontSize: '14px',
                                                    color: 'white',
                                                    fontWeight: 'regular',
                                                    textAlign: 'left',
                                                    paddingLeft: "16px",
                                                    paddingRight: "12px",
                                                    padding: '4px',
                                                    width: '170px',
                                                    fontFamily: 'Product Sans',
                                                }}>{val.name}</td>
                                                <td style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontFamily: 'inter',
                                                    fontSize: '14px',
                                                    color: 'white',
                                                    fontWeight: 'regular',
                                                    textAlign: 'right',
                                                    paddingLeft: "12px",
                                                    paddingRight: "12px",
                                                    padding: '4px',
                                                    width: '75px',
                                                    fontFamily: 'Product Sans',

                                                }}>{val.actual}</td>
                                                <td style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontFamily: 'inter',
                                                    fontSize: '14px',
                                                    color: 'white',
                                                    fontWeight: 'regular',
                                                    textAlign: 'right',
                                                    paddingLeft: "12px",
                                                    paddingRight: "12px",
                                                    padding: '4px',
                                                    width: '75px',
                                                    fontFamily: 'Product Sans',


                                                }}>{val.points}</td>

                                            </tr>
                                        </div>


                                    )
                                })}
                            </table>
                        </div>
                        <div className="actions">
                            {/* <button
        className="button"
        onClick={() => {
            console.log('modal closed ');
            close();
        }}
    >
        close modal
    </button> */}
                        </div>
                    </div>}
                </div>




            )
            }

        </Popup >
    );
};



export default PlayerStats;