import React from 'react'

function CloseButton() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none" >
            <g filter="url(#filter0_b_528_5786)">
                <circle cx="15" cy="15.5" r="15" fill="url(#paint0_linear_528_5786)" fillOpacity="0.3" />
            </g>
            <path d="M9.6001 10.1001L15.0001 15.5001M15.0001 15.5001L20.4001 20.9001M15.0001 15.5001L9.6001 20.9001M15.0001 15.5001L20.4001 10.1001" stroke="white" strokeWidth="2" />
            <defs>
                <filter id="filter0_b_528_5786" x="-48.8029" y="-48.3029" width="127.606" height="127.606" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="24.4014" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_528_5786" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_528_5786" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_528_5786" x1="15" y1="-9.96512" x2="15" y2="47.3605" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default CloseButton