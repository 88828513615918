import React, { useEffect, useState } from 'react'
import images from '../helper/images';
import DownArrow from './icon_component/down_arrow';
import ApiService from '../ApiService';
import AppString from '../appstring'
import Endpoints from '../Endpoints';
import PlayerStats from '../components/player_status_popup/player_status_popup'
import {
    useQuery,
} from 'react-query';
import CustomizeLoader from './custom_loading/custom_loader';
import { cacheTime, staleTime } from '../helper/common';

import BackgroundHeader from "../assets/img/leaderboard-header-bg.png"

const BackgroundHead = {
    backgroundImage: 'url(' + BackgroundHeader + ')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

function Stats({ matchId }) {

    const [isAsc, setAsc] = useState(false)

    const { isLoading, isError, data: statList, error, refetch } = useQuery(`leaderboard-stats-${matchId}-${isAsc}`, async () => {

        try {
            const response = await ApiService.get(Endpoints.STATLIST + `?match_id=${matchId}&asc=${isAsc}`);
            if (response.status) {
                console.log("alter enter", response);
                return response.data.playerStats || [];
            }
            return []
        } catch (error) {
            console.error("Error fetching data:", error.message);
            return []
        }
    },
        {
            staleTime: staleTime,
            cacheTime: cacheTime
        }
    )

    useEffect(() => {
        refetch()

    }, [isAsc])

    console.log("statList", statList);


    return (
        <div>
            <div style={BackgroundHead} class="text-left flex justify-between leaderTitle px-5 py-3 h-[40px]">
                <div class=''>PLAYERS</div>
                <div class='flex items-center'><span className='mr-2'>BASE PTS </span> <span onClick={() => { setAsc(!isAsc) }} className={isAsc ? "rotate-180" : ""}><DownArrow></DownArrow></span></div>
            </div>

            {isLoading ? <div className='w-full text-center'>
                <CustomizeLoader className="mt-16 " size={65}></CustomizeLoader>
            </div> : <div className='w-full mt-2'>
                <div class="flex flex-col">
                    {
                        statList.length <= 0 ? <div className='flex flex-col items-center justify-center h-screen'>
                            {AppString.STATSNOTFOUND}
                        </div> : statList.map((e, i) => {
                            return (
                                <PlayerStats key={"stats12232" + i} playerId={e.player_id} matchId={matchId}>
                                    <div class="font-medium flex items-center justify-between leaderboardStatsBg px-5 py-2 mb-2">

                                        <div className='flex items-center'>
                                            <img class='w-[40px] h-[60px]' src={e.image_url}></img>

                                            <div className='ml-2.5'>
                                                <h6 className='text-base text-white font-bold'>{e.player_name}</h6>
                                                <div className='mt-1'>
                                                    <span className='rounded-full font-sans text-white text-xs bg-[#8100AC] font-normal px-3 py-1 mr-1 uppercase'>{e.team_name}</span>
                                                    <span className='rounded-full font-sans text-white text-xs bg-[#AC5D00] font-normal px-3 py-1 ml-1 uppercase'>{e.role}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className='text-base text-white font-bold mr-2'>{e.f_points}</h5>

                                    </div>
                                </PlayerStats>

                            )
                        })
                    }
                </div>
            </div>}
        </div >
    )
}

export default Stats